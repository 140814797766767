/* istanbul ignore file */

import {HttpContext, HttpErrorResponse} from '@angular/common/http';
import {AbstractControl, FormGroup} from '@angular/forms';
import {MatSelectChange as AngularMatSelectChange} from '@angular/material/select';
import {AddressResidentialIndicator} from '@common/enum/addressResidentialIndicator.enum';
import {ApiRoles} from '@common/enum/apiRoles.enum';
import {BackofficeRoles} from '@common/enum/backofficeRoles.enum';
import {BooleanOptions} from '@common/enum/boolean.enum';
import {CloneOptions} from '@common/enum/cloneSettings.enum';
import {CommercialModel} from '@common/enum/commercialModel';
import {CommunicationBoardTagTypes} from '@common/enum/communicationBoardTagTypes.enum';
import {CommunicationBoardTypes} from '@common/enum/communicationBoardTypes';
import {CompanyRegistrationSteps} from '@common/enum/companyRegistrationSteps.enum';
import {ConditionType} from '@common/enum/conditionType.enum';
import {CustomerSegments} from '@common/enum/customerSegments';
import {CustomerTypes} from '@common/enum/customerTypes';
import {CustomsStatus} from '@common/enum/customsStatus.enum';
import {DefaultValueStatus} from '@common/enum/defaultValueStatus.enum';
import {DiscountNameType} from '@common/enum/discountNameType.enum';
import {EbayConditions} from '@common/enum/ebayConditions.enum';
import {EbayListingTypes} from '@common/enum/ebayListingType';
import {EbayMeasurementSystemType} from '@common/enum/EbayMeasurementSystemType.enum';
import {EbayMessageQuestionType} from '@common/enum/ebayMessageQuestionTypes.enum';
import {EbayOrderCancelReason} from '@common/enum/ebayOrderCancelReason.enum';
import {EbayPolicies} from '@common/enum/ebayPolicyTypes.enum';
import {EtsyItemDimensionUnit} from '@common/enum/EtsyItemDimensionUnit.enum';
import {EtsyItemWeightUnit} from '@common/enum/EtsyItemWeightUnit.enum';
import {FieldTypes} from '@common/enum/fieldTypes.enum';
import {HistoryTypes} from '@common/enum/historyEventTypes.enum';
import {IdentifierTypes} from '@common/enum/identifierTypes.enum';
import {InventoryPolicy} from '@common/enum/inventoryPolicy.enum';
import {JobStatus} from '@common/enum/jobStatus.enum';
import {LabelPrintTypes} from '@common/enum/labelPrintTypes.enum';
import {LayoutTypes} from '@common/enum/layoutTypes.enum';
import {ListingDeleteReason} from '@common/enum/listingDeleteReason.enum';
import {ListingDuration} from '@common/enum/listingDuration.enum';
import {ListingRejectReason} from '@common/enum/listingRejectReason.enum';
import {ListingSaveType} from '@common/enum/listingSaveType.enum';
import {ListingErrorSeverity} from '@common/enum/listingSeverity.enum';
import {ListingStatus} from '@common/enum/listingStatus.enum';
import {ListingType} from '@common/enum/listingType.enum';
import {ListingUploadType} from '@common/enum/listingUploadType.enum';
import {LocationBoundDataType} from '@common/enum/locationBoundDataType.enum';
import {LocationDialogTypes} from '@common/enum/locationDialogTypes.enum';
import {LocationTypes} from '@common/enum/locationType.enum';
import {ManifestStatus} from '@common/enum/manifestStatus.enum';
import {MarketPlaces} from '@common/enum/marketPlaces.enum';
import {MessageStatus} from '@common/enum/messageStatus.enum';
import {MessageType} from '@common/enum/messageType.enum';
import {ModuleAccessTypes} from '@common/enum/moduleAccessTypes';
import {ModuleTypes} from '@common/enum/moduleTypes.enum';
import {NotificationAttachmentType} from '@common/enum/notificationAttachmentType.enum';
import {NotificationGroupType} from '@common/enum/notificationGroupType.enum';
import {NotificationType} from '@common/enum/notificationType.enum';
import {Occasion} from '@common/enum/occasion.enum';
import {OrderAssignmentType} from '@common/enum/orderAssignmentType.enum';
import {OrderFulfillmentStatus} from '@common/enum/orderFulfillmentStatus.enum';
import {OrderPaymentStatus} from '@common/enum/orderPaymentStatus.enum';
import {PaymentMethods} from '@common/enum/paymentMethods.enum';
import {PdfSortTypes} from '@common/enum/pdfSortTypes';
import {PickingStatus} from '@common/enum/pickingStatus.enum';
import {PrintedTypes} from '@common/enum/printedTypes.enum';
import {ProductRejectReason} from '@common/enum/productRejectReason.enum';
import {PublishedScopes} from '@common/enum/publishedScopes.enum';
import {QueryResultColumnType} from '@common/enum/queryResultColumnType.enum';
import {QueryRunMode} from '@common/enum/queryRunMode.enum';
import {Recipient} from '@common/enum/recipient.enum';
import {RefundOption} from '@common/enum/refoundOption.enum';
import {RelativeValues} from '@common/enum/relativeValueTypes.enum';
import {ReleaseMessageTypes} from '@common/enum/releaseMessageTypes.enum';
import {RepriceFields} from '@common/enum/repriceFields.enum';
import {RepriceTypes} from '@common/enum/repriceTypes.enum';
import {ReturnedWithin} from '@common/enum/returnedWithin.enum';
import {Roles} from '@common/enum/roles.enum';
import {ScheduledReportInterval} from '@common/enum/scheduledReportInterval.enum';
import {ShipmentConfirmation} from '@common/enum/shipmentConfirmation.enum';
import {ShipmentContents} from '@common/enum/shipmentContents.enum';
import {ShipmentCurrency} from '@common/enum/shipmentCurrency.enum';
import {ShipmentDimensionUnit} from '@common/enum/shipmentDimensionUnit.enum';
import {ShipmentLabelStatus} from '@common/enum/shipmentLabelStatus.enum';
import {ShipmentNonDelivery} from '@common/enum/shipmentNonDelivery.enum';
import {ShipmentStatus} from '@common/enum/shipmentStatus.enum';
import {ShipmentTrackingStatus} from '@common/enum/shipmentTrackingStatus.enum';
import {ShipmentWeightUnit} from '@common/enum/shipmentWeightUnit.enum';
import {PaidBy} from '@common/enum/shippingCostPaidBy.enum';
import {ShopiagoSearchAreas} from '@common/enum/shopiagoSearchAreas.enum';
import {ElasticsSearchFrom} from '@common/enum/shopiagoSearchTypes.enum';
import {ShopSubmissionStatus} from '@common/enum/shopSubmissionStatus.enum';
import {SiteId} from '@common/enum/siteId.enum';
import {TagTypes} from '@common/enum/tagTypes.enum';
import {TaxableEntityTypes} from '@common/enum/taxableEntityTypes';
import {TaxIdTypes} from '@common/enum/taxIdTypes.enum';
import {TransferBoxStatus} from '@common/enum/transferBoxStatus.enum';
import {UserGroups} from '@common/enum/userGroups.enum';
import {WhatIsIt} from '@common/enum/whatIsIt.enum';
import {WhenMade} from '@common/enum/whenMade.enum';
import {WhoMade} from '@common/enum/whoMade.enum';
import {ConditionTypes} from '@common/globals/conditionFilter';
import {GridRequest} from '@common/globals/gridRequest';
import {GridResponse} from '@common/globals/gridResponse';
import {FinalFormArray, FinalFormGroup} from '@common/statics/formHelper';
import {
  EbayDefaultValuesForm,
  EbayPolicyGroup,
  EbayShippingMethodGroup,
  EtsyDefaultValuesForm,
  EtsyOtherGroup,
  ItemSpecificsGroup,
  NumberFormArray,
  PricingGroup,
  RestockVariant,
  ReturnPolicyFormGroup,
  ShippingDimensionGroup,
  ShippingPackageDetailsGroup,
  ShopifyDefaultValuesForm,
  TextFormArray,
  VariantArray,
} from '@common/ts/formInterfaces';
import {BarcodeFormat} from '@zxing/library';
import {ChartOptions, ChartType} from 'chart.js';
import {QuillModules} from 'ngx-quill';

declare global {
  interface Window {
    HubSpotConversations: {
      clear(config?: {
        resetWidget?: boolean;
      }): void;
      off(): void;
      on(event: 'conversationStarted' | 'conversationClosed' | 'unreadConversationCountChanged', payload: () => {
        conversation?: {
          conversationId: number;
        };
        message?: string;
        unreadCount?: string;
      }): void;
      resetAndReloadWidget(): void;
      widget: {
        close(): void;
        load(config?: {
          widgetOpen?: boolean;
        }): void;
        open(): void;
        refresh(): void;
        remove(): void;
        status(): { loaded: boolean; };
      };
    };
    _linkedin_data_partner_ids: string[];
    dataLayer: any[];
    hsConversationsSettings: {
      disableAttachment?: boolean;
      enableWidgetCookieBanner?: boolean;
      identificationEmail: string;
      identificationToken: string;
      inlineEmbedSelector?: string;
      loadImmediately?: boolean;
    };
  }
}

export interface Interval {
  intervalEnd?: Date;
  intervalStart?: Date;
}

export interface Audit {
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface Audit2 {
  createdById: string;
  createdByName: string;
  createdOn: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedOn: string;
}

export interface FilterDTO {
  marketplaceChipSearchFilterDTO: MarketplaceChipSearchFilterDTO;
  sites: SiteDTO[];
}

export interface MarketplaceChipSearchFilterDTO {
  completedListings?: boolean;
  condition?: number;
  ean?: string;
  isbn?: string;
  keyword: string;
  listingType?: number;
  soldItemsOnly?: boolean;
}

export interface SiteDTO {
  marketplace: MarketPlaces;
  siteId: SiteKeys;
}

export interface EtsyActivateDTO {
  etsyAccountId: string;
  oauthVerification: string;
  token: string;
}

export interface EtsyCountryDTO {
  id: string;
  isoCountryCode: CountryCodes;
  name: string;
}

export interface EtsyRegionDTO {
  id: string;
  regionName: string;
}

export interface EtsyShippingCommonDTO {
  etsyId: number;
  id: string;
  mailClassId: string;
  maxDeliveryTime: number;
  minDeliveryTime: number;
  shippingCarrierId: string;
}

export interface EtsyShippingTemplateEntityDTO extends EtsyShippingCommonDTO, Audit2 {
  currencyCode: CurrencyCodes;
  destinationCountryId: string;
  destinationCountryName: string;
  destinationRegionId: string;
  destinationRegionName: string;
  mailClassName: string;
  primaryCost: number;
  secondaryCost: number;
  shippingCarrierName: string;
  shippingTemplateId: string;
}

export interface EtsyShippingTemplateCreateEntityDTO extends EtsyShippingCommonDTO {
  currencyCode: CurrencyCodes;
  destinationCountryId: string;
  destinationRegionId: string;
  primaryCost: number;
  secondaryCost: number;
  shippingTemplateId: string;
}

export interface EtsyShippingMethodDTO extends EtsyShippingCommonDTO, Audit2 {
  etsyAccountId: string;
  mailClassName: string;
  maxProcessingDays: number;
  minProcessingDays: number;
  originCountryId: string;
  originCountryName: string;
  originPostalCode: string;
  shippingCarrierName: string;
  shippingTemplateEntityDTOList: EtsyShippingTemplateEntityDTO[];
  shippingTemplateEntityListDTOList: EtsyShippingTemplateEntityDTO[];
  title: string;
}

export interface EtsyShippingMethodCreateDTO extends EtsyShippingCommonDTO {
  etsyAccountId: string;
  maxProcessingDays: number;
  minProcessingDays: number;
  originCountryId: string;
  originPostalCode: string;
  shippingTemplateEntryDTOList: EtsyShippingTemplateCreateEntityDTO[];
  title: string;
}

export interface EtsyShippingMethodListDTO extends EtsyShippingMethodDTO {
  expanded: boolean;
  selected: boolean;
}

export interface EtsyCarrierDTO {
  countryId: string;
  domesticClasses: CarrierClass[];
  id: string;
  internationalClasses: CarrierClass[];
  name: string;
  originCountryIso: CountryCodes;
  shippingCarrierId: number;
}

export interface CarrierClass {
  id: string;
  mailClassKey: string;
  name: string;
}

export interface ShopifyAccountDetail {
  currency: CurrencyCodes;
  domain: string;
  email: string;
  enabled: boolean;
  id: string;
  isDeleted: boolean;
  locked: boolean;
  name: string;
  setupRequired: boolean;
  shopOwner: string;
  shopifyId: number;
}

export interface AccountListDTO extends Audit2 {
  alternateName: string;
  continuousSyncEnabled: boolean;
  email: string;
  enabled: boolean;
  historicalSyncEnabled: boolean;
  id: string;
  isReattachAvailable: boolean;
  locked: boolean;
  messageSyncEnabled: boolean;
  name: string;
  selected: boolean;
  syncLocationId: string;
  tokenStatus: string;
  type: MarketPlaces;
}

export interface EbayShippingMethodListDTO extends Audit2 {
  currency: CurrencyCodes;
  description: string;
  ebaySite: ListingSites;
  expedited: boolean;
  expired: boolean;
  freeShipping: boolean;
  id: string;
  inUse: boolean;
  isInternational: boolean;
  price: number;
  shippingId: string;
  shippingPackages: EbayShippingServicePackageDTO[];
  shippingTimeMax: number;
  shippingTimeMin: number;
  siteName: string;
}

export interface EbayShippingMethodSelectorListDTO {
  description: string;
  id: string;
  isInternational: boolean;
  price: number;
  shippingPackages: EbayShippingServicePackageDTO[];
}

export interface EbayShippingMethodGridDTO extends EbayShippingMethodListDTO {
  selected: boolean;
}

export interface EbaySiteDTO {
  currency1: CurrencyCodes;
  currency2: CurrencyCodes;
  globalId: SiteKeys;
  language: string;
  name: ListingLocations;
  siteId: SiteId;
  siteName: string;
  value: ListingSites;
}

export interface EbayShippingMethodsBySiteDTO {
  description: string;
  descriptionExtra: string;
  id: string;
  name: string;
  shippingPackages: EbayShippingServicePackageDTO[];
}

export interface EbayAccessTokenDTO {
  additionalInformation: any;
  expiration: any;
  expired: boolean;
  expiresIn: number;
  refreshToken: any;
  scope: any[];
  tokenType: string;
  value: string;
}

export type EbayAccountLinkState = 'attach' | 'registration';

export interface EbayParamsDTO {
  code: string;
  expires_in: number;
  state: EbayAccountLinkState;
}

export interface EtsyParamsDTO {
  oauth_token: string;
  oauth_verifier: string;
}

export interface AttachEbayAccountDTO {
  accessToken: EbayAccessTokenDTO;
  isRegistrationSuccess: boolean;
  message: string;
  username: string;
}

export interface EbayCategoryDTO {
  id: string;
  isLeaf: boolean;
  level: number;
  name: string;
  parentId: string;
}

export interface EtsyCategoryDTO {
  id: string;
  isLeaf: boolean;
  isSelectable: boolean;
  level: number;
  name: string;
  parentId: string;
  taxonomyId: string;
}

export interface ListingDurationDTO {
  description: string;
  textValue: string;
  value: ListingDuration;
}

export interface ConditionDTO {
  id: EbayConditions;
  name: string;
}

export interface SpecificDTO {
  aspectEnabledForVariations: boolean;
  helpText: string;
  helpUrl: string;
  maxValues: number;
  minValues: number;
  name: string;
  required: boolean;
  selectionMode: ItemSpecificType;
  usageConstraint: string;
  valueRecommendations: string[];
}

export interface ItemSpecificCreateDTO {
  availableItemSpecifics: ItemSpecificsDataDTO[];
  helpText: string;
  isCustom: boolean;
  maxValues: number;
  minValues: number;
  name: string;
  required: boolean;
  selectedSpecific: ItemSpecificsDataDTO;
  selectionMode: ItemSpecificType;
  value: any;
  valueRecommendations: string[];
}

export interface EbayCategoryDataDTO {
  auctionListingDurations: ListingDurationDTO[];
  conditionEnabled: ConditionType;
  conditions: ConditionDTO[];
  domesticReturnsDurations: EbayReturnsWithinDTO[];
  eanRequired: boolean;
  ebayId: number;
  ebaySite: ListingSites;
  expired: boolean;
  fixedPriceListingDurations: ListingDurationDTO[];
  globalShippingEnabled: boolean;
  id: string;
  isLeaf: boolean;
  isVirtual: boolean;
  isbnRequired: boolean;
  level: number;
  name: string;
  optionalSpecifics: SpecificDTO[];
  parentId: string;
  recommendedSpecifics: SpecificDTO[];
  requiredSpecifics: SpecificDTO[];
  upcRequired: boolean;
  variationsEnabled: boolean;
}

export interface CategorySearchDTO {
  categoryTree: CategorySearchDTO[];
  categoryTreeString: string;
  ebayId: number;
  id: string;
  name: string;
  variationsEnabled: boolean;
}

export type CategoryTreeDTO = Record<number, CategorySearchDTO>;

export interface EbayShopCategoryDTO {
  id: string;
  isLeaf: boolean;
  level: number;
  name: string;
  parentId: string;
  shopCategoryId: number;
}

export interface EbayRefundDTO {
  description: string;
  refundOption: RefundOption;
}

export interface EbayReturnsAcceptedDTO {
  description: string;
  returnsAcceptedOption: BooleanOptions;
}

export interface EbayReturnsWithinDTO {
  description: string;
  returnsWithinOption: ReturnedWithin;
}

export interface EbayShippingCostPaidDTO {
  description: string;
  shippingCostPaidByOption: PaidBy;
}

export interface EbayReturnPolicyProperties {
  ebayRefundOptionList: EbayRefundDTO[];
  ebayReturnsAcceptedList: EbayReturnsAcceptedDTO[];
  ebayReturnsWithinList: EbayReturnsWithinDTO[];
  ebayShippingCostPaidByList: EbayShippingCostPaidDTO[];
  ebaySite: ListingSites;
  id: string;
}

export interface EbayDetailUiDTO extends ListingCommonDTO {
  archived: boolean;
  bestOfferAutoAcceptPrice: number;
  bestOfferEnabled: boolean;
  buyerPaymentMethods: PaymentMethods[];
  calculatedShippingDiscountId: string;
  conditionDescription: string;
  conditionId: EbayConditions;
  createdById: string;
  createdByName: string;
  createdOn: string;
  description: string;
  donationPercentage: number;
  ebayAccount: string;
  ebayFlatShippingReturnDTO: FlatRateListDTO;
  ebayInternationalFlatShippingReturnDTO: FlatRateListDTO;
  ebaySite: ListingSites;
  endReason: ListingDeleteReason;
  endTime: string;
  flatShippingDiscountId: string;
  forRemoval: boolean;
  fulfillmentPolicyId: string;
  fulfillmentPolicyName: string;
  globalShipping: boolean;
  highestBid: number;
  hitCount: number;
  internationalCalculatedShippingDiscountId: string;
  internationalFlatShippingDiscountId: string;
  inventoryCreatedFromListing: boolean;
  inventoryVariantItemDTOS: InventoryVariantItemDTO[];
  isCloneable: boolean;
  isHistoric: boolean;
  isInventoryCisOnly: boolean;
  isInventoryDraft: boolean;
  isItemBelongToActiveListing: boolean;
  isPrivate: boolean;
  isRelisted: boolean;
  isReviseVisible: boolean;
  itemSpecifics: StringObjectDTO;
  listedOn: string;
  listingDuration: ListingDuration;
  listingErrors: ListingErrorDTO[];
  listingId: string;
  marketplace: MarketPlaces;
  marketplaceUrl: string;
  minimumBestOfferPrice: number;
  numberOfRelists: number;
  offerEligible: boolean;
  optionalItemSpecifics: StringObjectDTO;
  originalDescription: string;
  paymentPolicyId: string;
  paymentPolicyName: string;
  primaryCategoryId: string;
  recommendedItemSpecifics: StringObjectDTO;
  refundOption: RefundOption;
  rejectReason: string;
  rejectType: ListingRejectReason;
  requiredItemSpecifics: StringObjectDTO;
  reservePrice: number;
  returnAccepted: BooleanOptions;
  returnPolicyDetails: string;
  returnPolicyId: string;
  returnPolicyName: string;
  returnsWithin: ReturnedWithin;
  savedImages: ImageDTO[];
  savedShippingMethods: EbayShippingMethodListDTO[];
  secondaryCategoryId: string;
  secondaryShopCategoryId: string;
  sentOffers: OfferDTO[];
  shippingCostPaidBy: PaidBy;
  shippingMethods: EbayListingShippingMethodDTO;
  shippingPackageDetails: ShippingPackageDetailsDTO;
  shopCategoryId: string;
  shopLocationAreaCode: string;
  sku: string;
  status: ListingStatus;
  stockPhotoURL: string;
  title: string;
  type: ListingType;
  useCatalogProduct: boolean;
  variants: ListingDetailsVariantDTO[];
  vatPercentage: number;
}

export interface EtsyDetailUiDTO extends ListingCommonDTO {
  archived: boolean;
  createdById: string;
  createdByName: string;
  createdOn: string;
  customizable: boolean;
  description: string;
  digital: boolean;
  endTime: string;
  etsyAccount: string;
  etsyShopId: number;
  etsyShopSectionId: number;
  featuredRank: number;
  fileData: string;
  forRemoval: boolean;
  highestBid: number;
  hitCount: number;
  inventoryCreatedFromListing: boolean;
  inventoryVariantItemDTOS: InventoryVariantItemDTO[];
  isCloneable: boolean;
  isHistoric: boolean;
  isInventoryCisOnly: boolean;
  isInventoryDraft: boolean;
  isItemBelongToActiveListing: boolean;
  isReviseVisible: boolean;
  isSupply: WhatIsIt;
  itemDimensionsUnit: EtsyItemDimensionUnit;
  itemHeight: number;
  itemLength: number;
  itemWeight: number;
  itemWeightUnit: EtsyItemWeightUnit;
  itemWidth: number;
  listedOn: string;
  listingErrors: ListingErrorDTO[];
  listingId: string;
  marketplace: MarketPlaces;
  marketplaceUrl: string;
  materials: string[];
  nonTaxable: boolean;
  numberOfRelists: number;
  occasion: Occasion;
  originalDescription: string;
  processingTimeMax: number;
  processingTimeMin: number;
  recipient: Recipient;
  rejectReason: string;
  rejectType: ListingRejectReason;
  savedImages: ImageDTO[];
  savedShippingTemplate: EtsyShippingMethodDTO;
  shippingTemplateId: string;
  shopLocationAreaCode: string;
  shopSectionId: string;
  shopSectionName: string;
  sku: string;
  status: ListingStatus;
  style: string[];
  tags: string[];
  taxonomyId: string;
  title: string;
  variants: ListingDetailsVariantDTO[];
  whenMade: WhenMade;
  whoMade: WhoMade;
}

export interface ShopifyDetailUiDTO extends ListingCommonDTO {
  archived: boolean;
  createdById: string;
  createdByName: string;
  createdOn: string;
  description: string;
  forRemoval: boolean;
  inventoryCreatedFromListing: boolean;
  inventoryVariantItemDTOS: InventoryVariantItemDTO[];
  isCloneable: boolean;
  isHistoric: boolean;
  isInventoryCisOnly: boolean;
  isInventoryDraft: boolean;
  isItemBelongToActiveListing: boolean;
  isReviseVisible: boolean;
  listedOn: string;
  listingErrors: ListingErrorDTO[];
  listingId: string;
  marketplace: MarketPlaces;
  marketplaceUrl: string;
  numberOfRelists: number;
  originalDescription: string;
  productType: string;
  publishedScope: PublishedScopes;
  rejectReason: string;
  rejectType: ListingRejectReason;
  savedImages: ImageDTO[];
  shopLocationAreaCode: string;
  shopifyAccount: string;
  shopifyInventoryItemId: number;
  shopifyProductId: number;
  sku: string;
  status: ListingStatus;
  tags: string[];
  title: string;
  variants: ListingDetailsVariantDTO[];
  vendor: string;
}

export type ListingDetailUiType = EbayDetailUiDTO | EtsyDetailUiDTO | ShopifyDetailUiDTO;

export interface ListingErrorDTO {
  errorParameters: string;
  longMessage: string;
  severity: ListingErrorSeverity;
  shortMessage: string;
}

export interface UniqueProductIdentifiersDTO {
  uniqueProductIdentifierType: IdentifierTypes;
  values: { [key in keyof typeof IdentifierTypes]?: string };
}

export type EbayListingShippingMethodDTO = Record<string, {
  freeShipping: boolean;
  price: number;
}>;

export interface ShippingPackageDetailsDTO {
  measurementSystem: EbayMeasurementSystemType;
  packageDepth: number;
  packageLength: number;
  packageWidth: number;
  shippingIrregular: boolean;
  shippingPackageId: string;
  weightMajor: number;
  weightMinor: number;
}

export interface MasterListingDTO {
  description: string;
  duration: number;
  id: string;
  listingProcessDurationId: string;
  name: string;
  title: string;
}

export interface MasterListingDetailsDTO extends MasterListingDTO {
  hasRevisions: boolean;
}

export interface ListingVariantDTO {
  auctionStartPrice: number;
  giftAidNumber?: string;
  id: string;
  images: string[];
  inventoryPolicy: InventoryPolicy;
  inventoryVariantData: InventoryVariantData[];
  inventoryVariantDataItemIdsByInventoryVariantDataIds?: string[];
  inventoryVariantId: string;
  price: number;
  quantity: number;
  requiresShipping: boolean;
  secondarySku: string;
  shopifyInventoryItemId: number;
  shopifyProductId: number;
  shopifyProductVariantId: number;
  sku: string;
  toteBoxId?: string;
  uniqueProductIdentifiers: UniqueProductIdentifiersDTO[];
  variantSpecifics: StringObjectDTO;
}

export interface ListingDetailsVariantDTO {
  auctionStartPrice: number;
  giftAidNumber?: string;
  id: string;
  images: string[];
  inventoryPolicy: InventoryPolicy;
  inventoryVariantData: ListingInventoryVariantData[];
  inventoryVariantDataItemIdsByInventoryVariantDataIds?: string[];
  inventoryVariantId: string;
  isDeleted: boolean;
  price: number;
  quantity: number;
  requiresShipping: boolean;
  secondarySku: string;
  shopifyInventoryItemId: number;
  shopifyProductId: number;
  shopifyProductVariantId: number;
  sku: string;
  toteBoxId?: string;
  uniqueProductIdentifiers: UniqueProductIdentifiersDTO[];
  variantSpecifics: StringObjectDTO;
}

export interface ListingInventoryVariantData {
  cisRequested: boolean;
  fromListing: boolean;
  giftAidNumber: string;
  id: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  isDeleted: boolean;
  isHistoric: boolean;
  quantity: number;
  sourceShopLocationId: string;
  sourceShopLocationName: string;
  submissionStatus: ShopSubmissionStatus;
  toteBoxId: string;
}

export interface InventoryVariantData {
  availableQuantity: number;
  giftAidNumber: string;
  id: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  quantity: number;
  sourceShopLocationId: string;
  toteBoxId: string;
}

export interface ListingVariantListDTO {
  ean: string;
  giftAidNumber?: string;
  id: string;
  images: ImageDTO[];
  inventoryVariantId: string;
  price: string;
  quantity: number;
  sku: string;
  sourceShopLocationId?: string;
  toteBoxId?: string;
}

export interface ListingCommonDTO {
  autoCancelAfterDays: number;
  autoRecon: boolean;
  autoRelistLimit: number;
  autoRenewal: boolean;
  bidCount: number;
  follow: boolean;
  images: string[];
  inventoryItemId: string;
  inventoryItemLocationId: string;
  isBulk: boolean;
  isCollectInStore: boolean;
  isJoblot: boolean;
  isVariantListing: boolean;
  itemDescription: string;
  masterListingId: string;
  recordType: ListingSaveType;
  repriceRuleGroupIds: string[];
  revisionParentId: string;
  scheduledTime: string;
  secondarySku: string;
  shopLocationId: string;
  shopLocationName: string;
  shopiagoTags: TagDTO[];
  watchCount: number;
}

export interface EbayListingDTO extends ListingCommonDTO {
  bestOfferAutoAcceptPrice: number;
  bestOfferEnabled: boolean;
  buyerPaymentMethods: PaymentMethods[];
  calculatedShippingDiscountId: string;
  conditionDescription: string;
  conditionId: EbayConditions;
  description: string;
  donationPercentage: number;
  ebayAccount: string;
  ebaySite: ListingSites;
  flatShippingDiscountId: string;
  forRemoval: boolean;
  fulfillmentPolicyId: string;
  globalShipping: boolean;
  internationalCalculatedShippingDiscountId: string;
  internationalFlatShippingDiscountId: string;
  isPrivate: boolean;
  itemSpecifics: StringObjectDTO;
  listingDuration: ListingDuration;
  listingId: string;
  marketplace: MarketPlaces;
  minimumBestOfferPrice: number;
  originalDescription: string;
  paymentPolicyId: string;
  primaryCategoryId: string;
  refundOption: RefundOption;
  reservePrice: number;
  returnAccepted: BooleanOptions;
  returnPolicyDetails: string;
  returnPolicyId: string;
  returnsWithin: ReturnedWithin;
  secondaryCategoryId: string;
  secondaryShopCategoryId: string;
  shippingCostPaidBy: PaidBy;
  shippingMethods: EbayListingShippingMethodDTO;
  shippingPackageDetails: ShippingPackageDetailsDTO;
  shopCategoryId: string;
  status: ListingStatus;
  stockPhotoURL: string;
  title: string;
  type: ListingType;
  useCatalogProduct: boolean;
  variants: ListingVariantDTO[];
  vatPercentage: number;
}

export interface EtsyListingDTO extends ListingCommonDTO {
  customizable: boolean;
  description: string;
  digital: boolean;
  etsyAccount: string;
  etsyShopId: number;
  etsyShopSectionId: number;
  featuredRank: number;
  fileData: string;
  forRemoval: boolean;
  isSupply: WhatIsIt;
  itemDimensionsUnit: EtsyItemDimensionUnit;
  itemHeight: number;
  itemLength: number;
  itemWeight: number;
  itemWeightUnit: EtsyItemWeightUnit;
  itemWidth: number;
  listingId: string;
  marketplace: MarketPlaces;
  materials: string[];
  nonTaxable: boolean;
  occasion: Occasion;
  originalDescription: string;
  processingTimeMax: number;
  processingTimeMin: number;
  recipient: Recipient;
  shippingTemplateId: string;
  shopSectionId: string;
  status: ListingStatus;
  style: string[];
  tags: string[];
  taxonomyId: string;
  title: string;
  variants: ListingVariantDTO[];
  whenMade: WhenMade;
  whoMade: WhoMade;
}

export interface ShopifyListingDTO extends ListingCommonDTO {
  description: string;
  forRemoval: boolean;
  listingId: string;
  marketplace: MarketPlaces;
  originalDescription: string;
  productType: string;
  publishedScope: PublishedScopes;
  shopifyAccount: string;
  shopifyInventoryItemId: number;
  shopifyProductId: number;
  status: ListingStatus;
  tags: string[];
  title: string;
  variants: ListingVariantDTO[];
  vendor: string;
}

export interface MultipleListingDTO {
  ebayListingDTO: EbayListingDTO;
  etsyListingDTO: EtsyListingDTO;
  masterListingDTO: MasterListingDTO;
  repriceRuleGroupIds: string[];
  shopifyListingDTO: ShopifyListingDTO;
}

export interface MultipleListingResponseDTO {
  masterListingUiDTO: ListingListAdvancedDTO;
  result: Record<ResultIndex, string>;
}

export type ResultIndex = 'EBAY' | 'ETSY' | 'SHOPIFY';

export interface InventoryVariantItemDTO {
  giftAidNumber: string;
  inventoryVariantDataId: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  inventoryVariantName: string;
  quantity: number;
  secondarySku: string;
  sku: string;
  sourceShopLocationId: string;
  sourceShopLocationName: string;
  toteBoxId: string;
}

export class ListingTemplateDTO {

  body: string;
  companyId: string;
  id: string;
  isDeleted: boolean;
  name: string;

}

export interface ListingTemplateSaveDTO {
  body: string;
  name: string;
}

export interface ListingTemplateListDTO extends ListingTemplateDTO, Audit2 {
  selected: boolean;
}

export interface MessageReplyDTO {
  body: string;
  compositionTime: number;
  displayToPublic?: boolean;
  emailCopyToSender: boolean;
  images: MessageReplyImage[];
  questionType?: EbayMessageQuestionType;
  subject: string;
}

export interface DraftDTO {
  compositionTime: number;
  message: string;
}

export interface MessageReplyImage {
  base64: string;
  ebayUrl: string;
  name: string;
}

export interface MessageTemplateSaveDTO {
  name: string;
  text: string;
}

export interface MessageTemplateDTO extends Audit {
  id: string;
  name: string;
  text: string;
}

export interface MessageTemplateListDTO extends MessageTemplateDTO {
  selected: boolean;
}

export class ListingDataCommon {

  archived: boolean;
  autoCancelAfterDays: number;
  autoRecon: boolean;
  autoRelistLimit: number;
  autoRenewal: boolean;
  createdById: string;
  createdByName: string;
  createdOn: string;
  images: ImageDTO[];
  inventoryVariantGroup: GroupByResponseDTO<InventoryVariantItemDTO>[];
  isCollectInStore: boolean;
  isJoblot: boolean;
  isVariantListing: boolean;
  listedOn: string;
  location: LocationDTO;
  numberOfRelists: number;
  packageName: string;
  rejectReason: string;
  sku: string;
  tags: TagDTO[];
  uploadTime: number;
  variants: ListingDetailVariants[];

}

export interface ListingDetailVariants extends ListingDetailsVariantDTO {
  image: ImageDTO;
  listingVariantName: string;
}

export class ListingDetailDTO {

  ebayListingDTO: EbayDetailUiDTO;
  etsyListingDTO: EtsyDetailUiDTO;
  isAccessible: boolean;
  masterListingDTO: MasterListingDetailsDTO;
  repriceRuleGroupIds: string[];
  shopifyListingDTO: ShopifyDetailUiDTO;

}

export interface SavedListingListDTO extends Audit2 {
  accountIds: string[];
  areAccountsLinked: boolean[];
  companyId: string;
  listingIds: string[];
  marketplaces: MarketPlaces[];
  masterListingId: string;
  name: string;
  recordType: ListingSaveType;
  selected: boolean;
  status: number;
}

export interface AutomatedJobsList {
  description: string;
  displayName: string;
  lastRunTime: string;
  qrtzJobName: string;
  runInterval: number;
  scheduledRunTime: string;
  selected: boolean;
  startHour: number;
  statusCode: JobStatus;
}

export interface AllListingTemplateDTO {
  body: string;
  companyId: string;
  id: string;
  isDeleted: boolean;
  name: string;
}

export interface ImageSetPropertiesDTO {
  height: any;
  isVerified: boolean;
  url: string;
}

export interface ListPriceDTO {
  amount: number;
  currencyCode: CurrencyCodes;
}

export interface LowestOfferPriceDTO {
  amount: number;
  currencyCode: CurrencyCodes;
  setAmount: boolean;
  setCurrencyCode: boolean;
}

export interface AmazonImageSetDTO {
  category: string;
  largeImage: ImageSetPropertiesDTO;
  mediumImage: ImageSetPropertiesDTO;
  smallImage: ImageSetPropertiesDTO;
  swatchImage: ImageSetPropertiesDTO;
  thumbnailImage: ImageSetPropertiesDTO;
  tinyImage: ImageSetPropertiesDTO;
}

export interface AmazonImagesDTO {
  imageSet: AmazonImageSetDTO[];
}

export interface ShopifyAccountDTO {
  code: string;
  hmac: string;
  host: string;
  shop: string;
  state: string;
  timestamp: number;
}

export interface BlockedKeywordDTO extends Audit2 {
  id: string;
  isGlobal: boolean;
  phrase: string;
}

export interface BlockedKeywordRequestDTO {
  phrase: string;
}

export interface BlockedKeywordListDTO extends BlockedKeywordDTO {
  selected: boolean;
}

export interface SearchSiteDTO {
  marketplace: MarketPlaces;
  name: string;
  siteId: SiteKeys;
  value: boolean;
}

export interface InventoryImportDTO {
  dateFormat: string;
  filename: string;
  mappedColumns: StringKeyDTO;
}

export type StringKeyDTO = Record<string, string>;

export interface InventoryImportCSVDTO {
  columnNames: string[];
  error: string;
  filename: string;
  headers: string[];
}

export interface InventoryDetailFieldDTO {
  name: string;
  order: number;
  type: FieldTypes;
  value: any;
  visibleRoles: Roles[];
}

export interface ImportProgressHistoryDTO extends Audit2 {
  companyId: string;
  errorCsvUrl: string;
  id: string;
  imageCount: number;
  imageErrorCount: number;
  imageProcessed: number;
  importProcessIdentifier: string;
  isFinished: boolean;
  rowCount: number;
  rowErrorCount: number;
  rowProcessed: number;
  selected: boolean;
}

export interface InventoryImportHistory extends Audit2 {
  companyId: string;
  finished: boolean;
  id: string;
  imageCount: number;
  imageErrorCount: number;
  imageProcessed: number;
  importProcessIdentifier: string;
  rowCount: number;
  rowErrorCount: number;
  rowProcessed: number;
}

export interface JobLotCreateDTO {
  inventoryDTO: InventoryItemDTO;
  inventoryIds: string[];
}

export class InventoryItemDTO {

  additionalData = '';
  availableQuantity: number;
  cisOnly: boolean;
  companyId: string;
  compositeSku: string;
  createdById: string;
  createdByName: string;
  createdOn: string;
  description: string;
  estimatedValue: number;
  follow: boolean;
  highValueItem: boolean;
  id: string;
  imageList: ImageDTO[] = [];
  imageThumbnailUrl: string;
  inventoryAdditionalDataSetId: string;
  isBelongToActiveListing: boolean;
  isBelongToListing: boolean;
  isDraft: boolean;
  isJoblot: boolean;
  warehouseLocationId: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedOn: string;
  name: string;
  quantity: number;
  secondarySku: string;
  selectedCategoryId: string;
  shopLocationAreaCode: string;
  shopLocationId: string;
  shopLocationName: string;
  sku: string;
  tagList: string[];
  tags: TagDTO[];
  variants: InventoryVariantDTO[];

}

export class InventoryVariantDTO {

  fromListing: boolean;
  id: string;
  imageList: ImageDTO[];
  index: number;
  inventoryId: string;
  isAccessible = true;
  isDeleted: boolean;
  isHistoric: boolean;
  name = '';
  secondarySku: string;
  secondarySkuBase: string;
  sku: string;
  skuBase: string;
  sourceVariantId: string;
  variantDetails: InventoryVariantDataDTO[] = [new InventoryVariantDataDTO()];

}

export class InventoryVariantDataDTO {

  cisRequested: boolean;
  fromListing: boolean;
  giftAidNumber = '';
  id: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  isDeleted: boolean;
  isHistoric: boolean;
  quantity = 1;
  sourceShopLocationId = '';
  sourceVariantDataId: string;
  sourceShopLocationName: string;
  submissionStatus: ShopSubmissionStatus;
  toteBoxId = '';

}

export interface VariantListDTO {
  arrivedInWarehouseList: string[];
  cisRequested: boolean;
  giftAidNumber: string;
  inventoryId: string;
  inventoryVariantDataId: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  rejectReasonList: string[];
  rejectType: ProductRejectReason;
  selected: boolean;
  sentToWarehouseList: string[];
  sourceShopLocationId: string;
  sourceShopLocationName: string;
  status: ShopSubmissionStatus;
  toteBoxId: string;
  transferBoxIdList: string[];
  variantName: string;
  variantQuantity: number;
  variantSecondarySku: string;
  variantSku: string;
}

export interface SubmittedItemsDTO extends Audit2 {
  arrivedInWarehouse: string;
  giftAidNumber: string;
  inventoryId: string;
  inventoryThumbnailUrl: string;
  name: string;
  rejectReason: string;
  rejectType: ListingRejectReason;
  selected: boolean;
  sentToWarehouse: string;
  sku: string;
  status: ShopSubmissionStatus;
  transferBoxId: string;
  variantName: string;
}

export interface InventoryVariantListDTO {
  giftAidNumber: string;
  inventoryVariantDataId: string;
  inventoryVariantDataItemIds: string[];
  inventoryVariantId: string;
  inventoryVariantName: string;
  quantity: number;
  secondarySku: string;
  sku: string;
  sourceShopLocationId: string;
  sourceShopLocationName: string;
  toteBoxId: string;
}

export interface InventoryListDTO extends Audit2 {
  additionalData: string;
  cisOnly: boolean;
  companyId: string;
  compositeSku: string;
  estimatedValue: string;
  expanded: boolean;
  hasMultipleArrivedInWarehouseValues: boolean;
  hasMultipleCisRequestedValues: boolean;
  hasMultipleGiftAidNumberValues: boolean;
  hasMultipleRejectDateValues: boolean;
  hasMultipleRejectReasonValues: boolean;
  hasMultipleRejectTypeValues: boolean;
  hasMultipleSentToWarehouseValues: boolean;
  hasMultipleSourceShopValues: boolean;
  hasMultipleStatusValues: boolean;
  hasMultipleToteBoxIdValues: boolean;
  hasMultipleTransferBoxIdValues: boolean;
  highValueItem: boolean;
  id: string;
  indeterminate: boolean;
  inventoryAdditionalDataSetId: string;
  inventoryThumbnailUrl: string;
  isBelongToListing: boolean;
  isJoblot: boolean;
  locationId: string;
  locationName: string;
  name: string;
  quantity: number;
  secondarySku: string;
  selected: boolean;
  selectedCategoryId: string;
  selectedCategoryName: string;
  shopLocationId: string;
  sku: string;
  tagList: string[];
  variantDataList: VariantListDTO[];
  warehouseLocationId: string;
}

export class ListingListDTO {

  id: string;
  companyId: string;
  title: string;
  marketplace: MarketPlaces;
  site: ListingSites;
  accountId: string;
  accountName: string;
  createdByName: string;
  createdById: string;
  createdOn: string;
  modifiedByName: string;
  modifiedById: string;
  modifiedOn: string;
  listedOn: string;
  status: ListingStatus;
  categoryName: string;
  auctionStartPrice: number;
  reservePrice: number;
  bestOfferEnabled: boolean;
  bestOfferAutoAcceptPrice: number;
  minimumBestOfferPrice: number;
  currency: CurrencyCodes;
  listingType: ListingType;
  sku: string;
  secondarySku: string;
  toteBoxId: string;
  url: string;
  inventoryItemLocationId: string;
  inventoryItemLocationName: string;
  listingThumbnailUrl: string;
  hasRevision: boolean;
  inventoryId: string;
  isCloneable: boolean;
  isHistoric: boolean;
  isLinked: boolean;
  isVariant: boolean;
  tagList: string[];
  teamId: string;
  teamName: string;
  isCurrentUserTeamLeaderForItem: boolean;
  shopAreaCode: string;
  shopLocationId: string;
  shopLocationName: string;
  numberOfRelists: number;
  autoRelistLimit: number;
  isBulk: boolean;
  isRelist: boolean;
  isRelisted: boolean;
  autoRenewal: boolean;
  giftAidNumber: string;
  forRemoval: boolean;
  archived: boolean;
  approvedByName: string;
  approvedById: string;
  approvedOn: string;
  lastSoldValue: number;
  lastOrderDate: string;
  endTime: string;
  secondaryCategory: string;
  listingConditionId: EbayConditions;
  isReviseVisible: boolean;
  repriceRuleName: string;
  listingUploadType: ListingUploadType;
  expectedUploadDate: string;
  autoCancelAfterDays: number;
  offerEligible: boolean;
  offerSentOn: string;
  masterListingId: string;
  variantName: string;
  variantSpecifics: StringObjectDTO;
  variantId: string;
  price: number;
  uploadQuantity: string;
  availableQuantity: string;
  selected: boolean;
  isLastListing: boolean;

}

export class ListingListAdvancedDTO extends ListingListDTO {

  description: string;
  indeterminate: boolean;
  records: ListingListDTO[];
  multipleColumnMap: MultipleColumnMapDTO;
  expanded: boolean;

}

export type MultipleColumnMapDTO = {
  [key in ListingListDTO as string]?: boolean;
};

export interface ReviseListDTO extends Audit2 {
  accountIds: string[];
  areAccountsLinked: boolean[];
  companyId: string;
  listingIds: string[];
  marketplaces: MarketPlaces[];
  masterListingId: string;
  name: string;
  recordType: ListingSaveType;
  selected: boolean;
  status: ListingStatus;
}

export interface TagDTO {
  id: string;
  tagText: string;
}

export interface BulkTagRequestDTO extends BulkIdDTO {
  tagTexts: string[];
}

export interface BulkTagGridRequestDTO extends MassGridRequest {
  tagTexts: string[];
}

export interface RepriceRuleData {
  ids: string[];
  listingTypes: ListingType[];
}

export interface ImageEditorDTO {
  image: ImageDTO;
  isPrimary?: boolean;
  primaryImage?: boolean;
}

export interface ImageEditorResultDTO {
  image: ImageDTO;
  isPrimary: boolean;
}

export interface ImageDTO {
  batchId: string;
  batchOrderId: number;
  externalImagePath: string;
  externalImageThumbnailPath: string;
  id: string;
  keepForever: boolean;
  originalName: string;
  tagText?: string[];
}

export interface ImageFormChangeEvent {
  event: UserEvent;
  images: ImageDTO[];
}

export interface ImageListDTO extends ImageDTO {
  selected: boolean;
}

export interface ImageGalleryDTO extends ImageListDTO {
  createdById: string;
  createdByName: string;
  createdOn: string;
  isImageUsed: boolean;
}

export interface SortDTO<T> {
  prop: keyof T;
  value: Sorting;
  viewName: string;
}

export interface LocationCreateDialog {
  isEcommerce?: boolean;
  isShop?: boolean;
}

export class LocationDTO {

  vendorNumber?: string;
  companyId?: string;
  companyName?: string;
  id: string;
  name: string;
  managerName: string;
  phone: string;
  addressPrimary: string;
  addressSecondary: string;
  country: string;
  county: string;
  town: string;
  postalCode: string;
  enabled: boolean;
  isDeleted: boolean;
  locationType: LocationTypes;
  areaCode: string;
  isCollectInStore: boolean;
  createdById: string;
  createdByName: string;
  createdOn: string;
  quantity?: number;
  modifiedByName: string;
  modifiedById: string;
  modifiedOn: string;
  isDefault: boolean;
  managerUserId: string;
  enableTypeChange: boolean;
  externalShopId: string;
  approvalNeeded: boolean;
  cisOnlyListing: boolean;
  hasEcommerceAccess: ModuleAccessTypes;
  hasTradeInAccess: ModuleAccessTypes;
  hasCollectionAccess: ModuleAccessTypes;
  isAdHocCollectionEligible: boolean;

}

export interface LocationListDTO extends LocationDTO {
  selected: boolean;
}

export interface InvalidLocationRowDTO {
  errorMessage: string;
  fieldName: string;
  fieldValue: string;
  uiKey: string;
}

export interface EbayShippingMethodDTO {
  currency: CurrencyCodes;
  id: string;
  price: number;
  shippingId: string;
  shippingPackages: EbayShippingServicePackageDTO[];
}

export interface EbayShippingServicePackageDTO {
  id: string;
  packageName: string;
}

export interface ThreadDTO extends Audit2 {
  archived: boolean;
  assignedUserId: string;
  assignedUserName: string;
  companyId: string;
  draftMessage: string;
  ebayAccountId: string;
  ebayAccountName: string;
  hasAttachedImage: boolean;
  hasDraft: boolean;
  hasUnreadMessage: boolean;
  id: string;
  itemId: string;
  lastMessageArrivalDate: string;
  lastMessageCreatedByName: string;
  lastMessagePreview: string;
  lastMessageSenderName: string;
  lastMessageSubject: string;
  listingId: string;
  listingTitle: string;
  masterListingId: string;
  messageFolderId: string;
  messageFolderName: string;
  orderIds: string[];
  productId: string;
  selected: boolean;
  senderDisplayName: string;
  status: MessageStatus;
  tagList: string[];
}

export interface CompanySettingsDTO {
  abbreviatedName: string;
  charityId: string;
  country: string;
  county: string;
  email: string;
  enabled: boolean;
  id: string;
  isCharity: boolean;
  name: string;
  phone: string;
  postalCode: string;
  streetAddress: string;
  town: string;
  vatId: string;
}

export interface CompanyDTO extends CompanySettingsDTO {
  createdOn: string;
  isApproved: boolean;
  isDeleted: boolean;
  registrationWizardProgress: CompanyRegistrationSteps;
}

export interface CompanyDataDTO {
  abbreviatedName: string;
  charityId: string;
  commercialModel: CommercialModel;
  country: string;
  county: string;
  customerSegment: CustomerSegments;
  customerSuccessManager: string;
  customerType: CustomerTypes;
  email: string;
  enabled: boolean;
  id: string;
  isCharity: boolean;
  name: string;
  percentage: number;
  phone: string;
  postalCode: string;
  startDate: Date;
  streetAddress: string;
  town: string;
  vatId: string;
}

export interface CompanyListDTO extends CompanyDTO {
  awaitingModules: ModuleTypes[];
  commercialModel: CommercialModel;
  createdOn: string;
  customerSegment: CustomerSegments;
  customerSuccessManager: string;
  customerSuccessManagerName: string;
  customerType: CustomerTypes;
  depotLocationId: string;
  depotLocationName: string;
  emailCollectionBreakdownReport: boolean;
  emailCollectionSealNumberReport: boolean;
  emailCollectionStatementReport: boolean;
  financeContactEmail: string;
  handoverDate: string;
  hasCollectionAccess: boolean;
  hasEcommerceAccess: boolean;
  hasTradeInAccess: boolean;
  invoiceEmail: string;
  isApproved: boolean;
  isCharity: boolean;
  isDeleted: boolean;
  isTestCompany: boolean;
  needListingApproval: boolean;
  newBusinessLead: string;
  newBusinessLeadName: string;
  onlineToteFee: number;
  pricePerKg: number;
  registrationWizardProgress: CompanyRegistrationSteps;
  selected: boolean;
  shippingServiceProvider: CollectionShippingProvider;
  stripeId: string;
}

export interface SystemSettingsDTO {
  autogenSkuPrefix: string;
  invitationDomainRestriction: string;
  isCisListingRequestEnabled: boolean;
  isGiftAidMandatory: boolean;
  isGlobalComBoardPostingEnabled: boolean;
  isGlobalComBoardVisible: boolean;
  isInstantListingEnabled: boolean;
  isRelocationMandatoryOnListing: boolean;
  isShipmentEnabledForManagers: boolean;
  isSubmissionProcessEnabled: boolean;
  isToteBoxIdMandatory: boolean;
  isTransferBoxValidationEnabled: boolean;
  listingDonationPercentage: number;
  needListingApproval: boolean;
  orderAssignmentType: OrderAssignmentType;
  submissionPriceLimit: number;
}

export interface EbaySettingsDTO {
  allowCashOnPickup: boolean;
  autoRelistLimitDefault: number;
  ebayDispatchTime: number;
  ebayPayPalEmailAddress: string;
  ignoreVerifyWarnings: boolean;
  isShopCategoryMandatoryOnListing: boolean;
  isWeightsAndDimensionsEnabled: boolean;
}

export interface GridFilterDTO<T> {
  filterData: string;
  filterName: string;
  gridName: T;
  id: string;
  isCompanyWide: boolean;
  isCreator: boolean;
  isGlobal: boolean;
  isSystemMade: boolean;
}

export interface GridFilter<T, R = T> {
  hidden?: boolean;
  isDefault?: boolean;
  isNewType?: boolean;
  operation: Correlations;
  prop: GridProps<T, R>;
  relativeValue: RelativeValues;
  value: any;
  viewName: string;
  viewOperation: string;
  viewValue: string;
}

export interface GridAggregation {
  operation: Operations;
  prop: string;
  viewName: string;
  viewValue: string;
}

export interface NotificationListDTO {
  checked: boolean;
  createdOn: string;
  id: string;
  message: string;
  messageNumValue: NotificationGroupType;
  notificationAttachments: NotificationAttachmentDTO[];
  recipientCompanyId: string;
  recipientId: string;
  selected: boolean;
  title: string;
  type: NotificationType;
}

export interface NotificationAttachmentDTO {
  attachmentValue: string;
  primary: boolean;
  type: NotificationAttachmentType;
}

export interface NotificationGroupDTO {
  attachments: NotificationAttachmentDTO[];
  enumValue: NotificationGroupType;
  lastNotificationDate: string;
  notificationIds: string[];
  related: string;
  title: string;
}

export interface NotificationBadgeDTO {
  notificationCount: number;
  notifications: NotificationGroupDTO[];
}

export class NotificationHistoryDTO {

  notifications: NotificationGroupDTO[] = [];
  pageNumber: number;
  pageSize: number;
  totalElements: number;

}

export interface ReleaseMessageDTO {
  collectionMessage: boolean;
  ecommerceMessage: boolean;
  imageUrl: string;
  messageBody: string;
  messageTitle: string;
  messageType: ReleaseMessageTypes;
  tradeInMessage: boolean;
}

export class ReleaseMessageDetailDTO {

  createdBy: string;
  createdOn: string;
  collectionMessage: boolean;
  ecommerceMessage: boolean;
  id: string;
  imageUrl: string;
  messageBody: string;
  messageTitle: string;
  messageType: ReleaseMessageTypes;
  modifiedBy: string;
  modifiedOn: string;
  senderId: string;
  tradeInMessage: boolean;

}

export interface ActiveUserDataDTO {
  actualName: string;
  companyName: string;
  lastLogin: string;
  name: string;
  sessionCount: number;
}

export class InSiteSearchQueryDTO {

  id: string;
  query: string;
  createdById: string;
  createdByName: string;
  createdOn: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedOn: string;

}

export class InSiteSearchQueryListDTO extends InSiteSearchQueryDTO {

  selected: boolean;

}

export class ReindexDTO {

  companyIdFilter: string[] = [];
  operations: number[] = [];

}

export interface ReleaseMessageGridDTO extends ReleaseMessageDetailDTO {
  selected: boolean;
}

export interface GenericReportQueryRequestDTO {
  amount: number;
  mode: QueryRunMode;
  modeIdentifier: string;
  offset: number;
  queryString: string;
}

export interface DisplayNameDesc {
  displayName: string;
  fieldName: string;
  possibleEnumValues: any;
  type: QueryResultColumnType;
}

export interface GenericReportQueryResultDTO {
  data: any[];
  displayNames: DisplayNameDesc[];
  errors: string[];
  sum: number;
  total: number;
}

export class GenericReportQuerySaveRequestDTO {

  id: string;
  queryString: string;
  name: string;
  editorData: string;

}

export interface GenericReportQueryListDTO extends GenericReportQuerySaveRequestDTO {
  selected: boolean;
}

export interface EbayOrderDTO {
  buyerUserId: string;
  currency: CurrencyCodes;
  ebayOrderId: string;
  id: string;
  isExpedited: boolean;
  orderId: string;
  orderPaymentMethod: PaymentMethods;
  refundedAmount: number;
  sellerUserId: string;
  shippingCost: number;
  shippingReferenceId: string;
  shippingService: string;
  subTotal: number;
  total: number;
}

export interface EtsyOrderDTO {
  adjustedGrandTotal: number;
  buyerUserId: number;
  currencyCode: CurrencyCodes;
  etsyReceiptId: number;
  giftMessage: string;
  giftWrapPrice: number;
  grandTotal: number;
  id: string;
  isGift: boolean;
  needGiftWrap: boolean;
  orderId: string;
  paymentMethod: PaymentMethods;
  sellerUserId: number;
  shippingCost: number;
  subTotal: number;
  total: number;
}

export interface ShopifyOrderDTO {
  cancelReason: number;
  cancelledAt: string;
  currency: CurrencyCodes;
  customerId: number;
  financialStatus: number;
  id: string;
  locationId: string;
  orderId: string;
  orderName: string;
  orderNote: string;
  orderNoteAttributes: OrderNoteAttributesDTO[];
  orderNumber: number;
  orderStatusUrl: string;
  posUserId: number;
  processingMethod: PaymentMethods;
  referringSite: string;
  shippingCost: number;
  shopifyOrderId: number;
  sourceName: string;
  subtotalPrice: number;
  tags: string[];
  token: string;
  totalLineItemsPrice: number;
  totalPrice: number;
  totalTax: number;
}

export interface OrderNoteAttributesDTO {
  name: string;
  value: string;
}

export class OrderDetailDTO {

  archived: boolean;
  buyerCheckoutMessage: string;
  buyerEmail: string;
  buyerName: string;
  cisShipping: boolean;
  city: string;
  country: string;
  ebayOrderDTO: EbayOrderDTO;
  etsyOrderDTO: EtsyOrderDTO;
  fulfillmentStatus: OrderFulfillmentStatus;
  id: string;
  marketplace: MarketPlaces;
  orderDate: string;
  orderFixedDate: string;
  paymentStatus: OrderPaymentStatus;
  phone: string;
  pickedStatus: PrintedTypes;
  postalCode: string;
  recipientName: string;
  setAsPaidOn: string;
  shopifyOrderDTO: ShopifyOrderDTO;
  state: string;
  street1: string;
  street2: string;

}

export interface OrderListDTO {
  archived: boolean;
  buyerUserId: string;
  cisShipping: boolean;
  clientEmail: string;
  creatingUserRole: string;
  currency: CurrencyCodes;
  customerName: string;
  expanded: boolean;
  fulfillmentStatus: OrderFulfillmentStatus;
  grandTotal: string;
  id: string;
  indeterminate: boolean;
  isAmended: boolean;
  isCombined: boolean;
  isKeyClient: boolean;
  isMultiOrder: boolean;
  marketplace: MarketPlaces;
  marketplaceAccountId: string;
  modifiedOn: string;
  numberOfItems: number;
  orderDate: string;
  orderId: string;
  orderItemIds: string[];
  orderItems?: OrderItemsDTO[];
  orderPaymentMethod: PaymentMethods;
  packingSlipPrintStatus: PrintedTypes;
  paymentDate: string;
  paymentManagedByMp: boolean;
  paymentStatus: OrderPaymentStatus;
  pickedStatus: PrintedTypes;
  printRunPrintStatus: PrintedTypes;
  refundedAmount: string;
  salesTax: string;
  selected: boolean;
  setAsPaidOn: string;
  shippingCost: number;
  subTotal: string;
  tagList: string[];
  tagListJsonb: string;
  totalBuyingPrice: string;
}

export interface OrderListAdvancedDTO {
  archived: boolean;
  buyerCity: string;
  buyerCountry: string;
  buyerEmail: string;
  buyerName: string;
  buyerPhone: string;
  buyerPostalCode: string;
  buyerState: string;
  buyerStreet1: string;
  buyerStreet2: string;
  cisShipping: boolean;
  companyId: string;
  currency: CurrencyCodes;
  customSku: string;
  finalValueFee: number;
  fulfilledQuantity: number;
  fulfillmentStatus: OrderFulfillmentStatus;
  id: string;
  imageThumbnailUrl: string;
  inventoryId: string;
  inventoryItemLocationName: string;
  isAmended: boolean;
  isCombined: boolean;
  isMultiOrder: boolean;
  leafCategory: string;
  listingId: string;
  locationId: string;
  marketplace: MarketPlaces;
  marketplaceAccountId: string;
  marketplaceOrderId: string;
  marketplaceSpecificId: string;
  masterListingId: string;
  orderDate: string;
  orderFulfillmentStatus: OrderFulfillmentStatus;
  orderId: string;
  orderPaymentMethod: PaymentMethods;
  packingSlipPrintStatus: PrintedTypes;
  paymentDate: string;
  paymentManagedByMp: boolean;
  paymentStatus: OrderPaymentStatus;
  price: number;
  printRunPrintStatus: PrintedTypes;
  quantity: number;
  refundDate: string;
  refundedAmount: number;
  rootCategory: string;
  salesRecordNumber: number;
  selected: boolean;
  setAsPaidOn: string;
  shippingCost: number;
  shippingService: string;
  shopLocationId: string[];
  sku: string;
  sourceShopNames: string[];
  title: string;
  total: number;
}

export interface OrderPickListDTO {
  archived: boolean;
  assignedTo: string;
  assignedToName: string;
  buyerCity: string;
  buyerCountry: string;
  buyerEmail: string;
  buyerName: string;
  buyerPhone: string;
  buyerPostalCode: string;
  buyerState: string;
  buyerStreet1: string;
  buyerStreet2: string;
  cisShipping: boolean;
  companyId: string;
  currency: CurrencyCodes;
  fulfillmentStatus: OrderFulfillmentStatus;
  giftAidNumber: string;
  id: string;
  inventoryId: string;
  inventoryLocationId: string;
  inventoryLocationName: string;
  inventoryVariantDataItemId: string;
  isAmended: boolean;
  isCombined: boolean;
  isMultiOrder: boolean;
  leafCategory: string;
  listingCreatorId: string;
  listingId: string;
  listingImageThumbnailUrl: string;
  marketplace: MarketPlaces;
  marketplaceAccountId: string;
  marketplaceAccountName: string;
  marketplaceOrderId: string;
  marketplaceSpecificId: string;
  masterListingId: string;
  orderDate: string;
  orderFulfillmentStatus: OrderFulfillmentStatus;
  orderId: string;
  orderItemId: string;
  orderPaymentMethod: PaymentMethods;
  packingSlipStatus: PrintedTypes;
  paymentDate: string;
  paymentStatus: OrderPaymentStatus;
  pickedBy: string;
  pickedByName: string;
  pickedOn: string;
  pickingStatus: PickingStatus;
  price: number;
  quantity: number;
  rootCategory: string;
  salesRecordNumber: string;
  secondarySku: string;
  selected: boolean;
  setAsPaidOn: string;
  shippingCost: number;
  shippingService: string;
  shopLocationId: string;
  shopLocationName: string;
  sku: string;
  title: string;
  toteBoxId: string;
  variantSecondarySku: string;
  variantSku: string;
  vat: number;
}

export interface OrderItemsDTO {
  archived: boolean;
  buyerCity: string;
  buyerCountry: string;
  buyerEmail: string;
  buyerName: string;
  buyerPhone: string;
  buyerPostalCode: string;
  buyerState: string;
  buyerStreet1: string;
  buyerStreet2: string;
  cisShipping: boolean;
  companyId: string;
  currency: CurrencyCodes;
  customSku: string;
  fulfilledQuantity: number;
  fulfillmentStatus: number;
  id: string;
  imageThumbnailUrl: string;
  inventoryId: string;
  isAmended: boolean;
  isCombined: boolean;
  isMultiOrder: boolean;
  leafCategory: string;
  listingId: string;
  locationId: string;
  marketplace: number;
  marketplaceAccountId: string;
  marketplaceOrderId: string;
  marketplaceSpecificId: string;
  masterListingId: string;
  orderDate: string;
  orderFulfillmentStatus: OrderFulfillmentStatus;
  orderId: string;
  orderPaymentMethod: PaymentMethods;
  packingSlipPrintStatus: PrintedTypes;
  paymentDate: string;
  paymentManagedByMp: boolean;
  paymentStatus: OrderPaymentStatus;
  price: number;
  printRunPrintStatus: PrintedTypes;
  quantity: number;
  refundDate: string;
  refundedAmount: number;
  rootCategory: string;
  salesRecordNumber: number;
  selected: boolean;
  shippingCost: number;
  shippingService: string;
  shopLocationId: string;
  sku: string;
  sourceShopNames: string;
  title: string;
  total: number;
}

export interface ThreadMessageDTO extends Audit2 {
  alignedToRight: boolean;
  content: string;
  ebayAccountId: string;
  ebayImageUrls: string[];
  ebayItemId: string;
  expirationDate: string;
  externalMessageId: string;
  flagged: boolean;
  folderName: string;
  highPriority: boolean;
  id: string;
  images: ImageDTO[];
  isDeleted: boolean;
  itemEndTime: string;
  itemTitle: string;
  messageId: string;
  messageType: MessageType;
  parsedText: string;
  read: boolean;
  receiveDate: string;
  recipientUserId: string;
  replied: boolean;
  responseEnabled: boolean;
  senderDisplayName: string;
  subject: string;
  text: string;
  threadId: string;
}

export interface AuthorityDTO {
  authority: keyof typeof Roles | keyof typeof BackofficeRoles | keyof typeof ApiRoles;
}

interface UserExtraData {
  id: string;
}
export class ShopiagoActivateDTO {

  access_token: string;
  authorities: AuthorityDTO[];
  companyModules: ModuleTypes[];
  expires_in: number;
  itemLocationModules: ModuleTypes[];
  refresh_token: string;
  scope: string;
  token_type: string;
  userExtraData: UserExtraData;
  websocketUser: string;

}

export interface HubspotTokenResponse {
  email: string;
  id: string;
  name: string;
  tokenExpiresAt: string;
  userId: string;
  visitorAccessToken: string;
}

export interface PasswordReset {
  accessToken: ShopiagoActivateDTO;
  isSuccessful: boolean;
}

export interface PasswordResetRequestDTO {
  password: string;
  passwordAgain: string;
  passwordResetToken: string;
}

export interface TokenCheckDTO {
  active: boolean;
  authorities: AuthorityDTO[];
  client_id: string;
  companyModules: ModuleTypes[];
  exp: number;
  itemLocationModules: ModuleTypes[];
  scope: string[];
  userExtraData: {
    id: string;
  };
  user_name: string;
  websocketUser: string;
}

export interface ShopiagoUserDTO {
  email: string;
  gameName: string;
  id: string;
  name: string;
  oldPassword: string;
  password: string;
  passwordAgain: string;
  phone: string;
}

export class ShopiagoUserDetailDTO {

  areaCode: string;
  companyId: string;
  createdBy: string;
  createdOn: string;
  isDeleted: boolean;
  email: string;
  enabled: boolean;
  id: string;
  lastLogin: string;
  locked: boolean;
  name: string;
  phone: string;
  roles: Roles[];
  userGroupId: UserGroups;
  attachedShopName: string;
  attachedShopId: string;
  attachedWarehouseId: string;
  attachedWarehouseName: string;

}

export class ShopiagoUserListDTO<T = Roles, R = UserGroups> {

  areaCode: string;
  attachedShopName: string;
  attachedShopId: string;
  createdOn: string;
  email: string;
  enabled: boolean;
  id: string;
  isActivated: boolean;
  lastLogin: string;
  lockedUntil: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedOn: string;
  name: string;
  oldPassword: string;
  password: string;
  passwordAgain: string;
  phone: string;
  selected: boolean;
  isDeleted: boolean;
  userGroupId: R;
  roleIds: T[];
  displayRoleIds: T[];
  displayUserGroupId: R;
  displayUserGroup: string;
  displayRoles: string[];

}

export interface UserGridListDTO {
  areaCode: string;
  attachedShopId: string;
  attachedShopName: string;
  attachedWarehouseId: string;
  attachedWarehouseName: string;
  createdOn: string;
  displayRoleIds: Roles[];
  displayRoles: string[];
  displayUserGroup: string;
  displayUserGroupId: UserGroups;
  email: string;
  enabled: boolean;
  id: string;
  isActivated: boolean;
  isDeleted: boolean;
  lastLogin: string;
  lockedUntil: string;
  modifiedById: string;
  modifiedByName: string;
  modifiedOn: string;
  name: string;
  oldPassword: string;
  password: string;
  passwordAgain: string;
  phone: string;
  roleIds: Roles[];
  selected: boolean;
  userGroupId: UserGroups;
}

export interface GiftAidRuleDTO {
  createdBy: string;
  createdOn: string;
  id: string;
  modifiedBy: string;
  modifiedOn: string;
  pattern: string;
  regexPattern: string;
  shopLevelEnabled: boolean;
  warehouseLevelEnabled: boolean;
}
export interface GiftAidRuleList extends GiftAidRuleDTO {
  createdByName: string;
  modifiedByName: string;
  selected: boolean;
}

export interface GiftAidRuleRequestDTO {
  id: string;
  pattern: string;
  shopLevelEnabled: boolean;
  warehouseLevelEnabled: boolean;
}

export interface GiftAidRulePatternDTO {
  helpText: string;
  regexPattern: RegExp;
}

export interface AccountSettingsDTO {
  ebayPaymentOptedIn: boolean;
  ebaySiteId: SiteId;
  globalShipping: boolean;
  isBusinessPoliciesOptedIn: boolean;
  isCombinedPaymentSpecified: boolean;
  overrideGspService: boolean;
}

export interface MarketplaceAccountDTO {
  alternateName: string;
  callLimitExceeded: boolean;
  ebayAccountSettings: AccountSettingsDTO[];
  etsyAccountSettings: EtsyShopDTO[];
  id: string;
  isActive: boolean;
  name: string;
  type: MarketPlaces;
}

export interface AccountDataDTO {
  accountCompleteCurrentStep: CompanyRegistrationSteps;
  allowEBayCashOnPickup: boolean;
  approvalFilterNeeded: boolean;
  charityId: string;
  companyListingApprovalEnabled: boolean;
  country: CountryCodes;
  ebayAutoRelistLimitDefault: number;
  email: string;
  enabledFeatureToggles: Record<Features, boolean>;
  hasShipengineAccount: boolean;
  id: string;
  ignoreEBayVerifyWarnings: boolean;
  invitationExpirationInDays: number;
  isCisListingRequestEnabled: boolean;
  isGiftAidMandatory: boolean;
  isGlobalComBoardPostingEnabled: boolean;
  isGlobalComBoardVisible: boolean;
  isInstantListingEnabled: boolean;
  isRelocationMandatoryOnListing: boolean;
  isShipmentEnabledForManagers: boolean;
  isShopCategoryMandatoryOnListing: boolean;
  isShopLister: boolean;
  isSubmissionProcessEnabled: boolean;
  isToteBoxIdMandatory: boolean;
  isTransferBoxValidationEnabled: boolean;
  isWeightsAndDimensionsEnabled: boolean;
  name: string;
  orderAssignmentType: OrderAssignmentType;
  shopLocationId: string;
  submissionPriceLimit: number | null;
  userApprovalNeeded: boolean;
  userCisOnlyListing: boolean;
  userGroupName: UserGroupNames;
  warehouseLocationId: string;
}

export interface TokenRequestListDTO extends ShopiagoUserListDTO {
}

export type StringsOnly<Type> = {
  [Property in keyof Type as Extract<Property, Type [Property] extends string ? Type [Property] : never>]: Type[Property];
};

export type TypeStringKeys<T> = keyof StringsOnly<T>;

export class ShopiagoCompanyDetail {

  abbreviatedName: string;
  awaitingModules: ModuleTypes[];
  charityId: string;
  commercialModel: CommercialModel;
  country: string;
  county: string;
  createdOn: string;
  customerSegment: CustomerSegments;
  customerSuccessManager: string;
  customerSuccessManagerName: string;
  customerType: CustomerTypes;
  depotLocationId: string;
  depotLocationName: string;
  email: string;
  emailCollectionBreakdownReport: boolean;
  emailCollectionSealNumberReport: boolean;
  emailCollectionStatementReport: boolean;
  enabled: boolean;
  financeContactEmail: string;
  handoverDate: string;
  hasCollectionAccess: boolean;
  hasEcommerceAccess: boolean;
  hasTradeInAccess: boolean;
  id: string;
  invoiceEmail: string;
  isApproved: boolean;
  isCharity: boolean;
  isDeleted: boolean;
  isTestCompany: boolean;
  name: string;
  needListingApproval: boolean;
  newBusinessLead: string;
  newBusinessLeadName: string;
  onlineToteFee: number;
  phone: string;
  postalCode: string;
  pricePerKg: number;
  registrationWizardProgress: CompanyRegistrationSteps;
  shippingServiceProvider: CollectionShippingProvider;
  streetAddress: string;
  stripeId: string;
  town: string;
  vatId: string;

}

export interface UserInvitationGroupDTO {
  id: UserGroups;
  name: string;
}

export interface BankAccountDTO {
  accountNumber?: string;
  financeContactEmail: string;
  iban?: string;
  name: string;
  sortCode?: string;
  swiftCode?: string;
}

export interface DailySoldItemStatDTO {
  currency: CurrencyCodes;
  marketplace: MarketPlaces;
  name: string;
  orderId: string;
  price: number;
  sku: string;
}

export interface UserPerfStatDTO {
  companyId: string;
  listingAvgDuration: number;
  listingAvgPrice: number;
  listingCount: number;
  listingSumDuration: number;
  listingSumPrice: number;
  marketplace: MarketPlaces;
  userId: string;
  userName: string;
}

export interface OrderStatDTO {
  newOrderCount: number;
  otherOrderCount: number;
}

export interface ListingStatDTO {
  activeListings: number;
  cancelledItems: number;
  rejectedItems: number;
  scheduledItems: number;
  scheduledOnMarketplaceItems: number;
  soldItems: number;
  suspendedForEditingItems: number;
  totalItems: number;
  unsoldItems: number;
  unsuccessfulListings: number;
  uploadInProgressItems: number;
  waitingForApprovalItems: number;
}

export interface InventoryStatDTO {
  allItemQuantityCount: number;
  inventoryItemCount: number;
}

export interface SystemAccountResetDTO {
  accountName: string;
  accountSecret: string;
}

export interface SystemAccountCreateDTO {
  accountName: string;
  accountSecret: string;
}

export interface SystemAccountRequestDTO {
  accountName: string;
  groupId: UserGroups;
}

export interface AllTemplateListDTO {
  id: string;
  name: string;
  supportedMarketplaces: MarketPlaces[];
}

export interface MarketplaceSearchSiteInfoDTO {
  fetchItemSpecific: boolean;
  globalSiteId: EbaySites;
  siteId: SiteIds;
  siteName: string;
}

export class MarketplaceSearchResultDTO {

  categoryName: string;
  condition: string;
  currency: CurrencyCodes;
  extendedSearch: boolean;
  galleryUrl: string;
  stockPhotoExists: boolean;
  isMultiVariationListing: boolean;
  isSubmissionPriceLimitExceeded: boolean;
  itemId: string;
  listingId: string;
  masterListingId: string;
  listingStatus: EbayListingTypes | ListingStatus;
  listingType: ListingType;
  location: string;
  marketplace: MarketPlaces;
  price: number;
  resultSiteInfo: MarketplaceSearchSiteInfoDTO;
  shippingPrice: number;
  targetSiteInfo: MarketplaceSearchSiteInfoDTO;
  title: string;

}

export interface MarketplaceSearchDTO {
  entriesPerPage: number;
  extendedSearch: boolean;
  pageNumber: number;
  results: MarketplaceSearchResultDTO[];
  totalEntries: number;
  totalPages: number;
}

export interface ImageSearchResponseDTO {
  response: MarketplaceSearchDTO;
  site: SiteKeys;
}

export type SiteKeyResult = {
  [key in SiteKeys]?: MarketplaceSearchDTO;
};

export interface ImageSearchResultDTO extends SiteKeyResult {
  totalEntries: number;
}

export interface RejectedListingListDTO {
  categoryName: string;
  currency: CurrencyCodes;
  errorOrRejectReason: string;
  eventOccurredOn: string;
  id: string;
  imageList: ImageDTO[];
  marketplace: MarketPlaces;
  masterListingId: string;
  price: number;
  status: ListingStatus;
  title: string;
}

export interface EbayFolderDTO extends Audit {
  companyId: string;
  id: string;
  lastMessageArrivalDate: string;
  name: string;
  threadCount: number;
  unreadThreadCount: number;
}

export interface ElasticSearchResponseDTO {
  additionalData: StringKeyDTO;
  buyerEmail?: string;
  buyerName?: string;
  checkoutMessage?: string;
  companyID: string;
  content?: string;
  createdByID?: string;
  description?: string;
  highlights: GridVariableMapDTO<any>;
  id: string;
  images?: ImageDTO[];
  itemSpecifics?: StringObjectDTO;
  marketplace?: MarketPlaces;
  masterListingId?: string;
  name?: string;
  quantity?: number;
  recordType?: ListingSaveType;
  secondarySku?: string;
  senderName?: string;
  sku?: string;
  status?: number;
  subject?: string;
  submissionStatus?: ShopSubmissionStatus;
  title?: string;
  totalQuantity?: number;
  type: ElasticsSearchFrom;
}

export interface ElasticSearch {
  hitCount: number;
  hits: ElasticSearchResponseDTO[];
}

export class ElasticSearchParamDTO {

  searchText: string;
  listingStatus: ListingStatus;
  searchListings = true;
  searchInventory = true;
  searchOrder = true;
  searchIncomingMessage = false;

}

export interface TeamMemberDTO {
  email: string;
  id: string;
  isLeader: boolean;
  name: string;
}

export class TeamListDTO {

  description: string;
  id: string;
  members: TeamMemberDTO[];
  name: string;
  userGroupId: UserGroups;

}

export interface TeamSelectorListDTO {
  id: string;
  name: string;
}

export interface TeamMemberCreateDTO {
  isLeader: boolean;
  teamId: string;
  userId: string;
}

export interface TeamCreateDTO {
  description: string;
  id: string;
  members: TeamMemberCreateDTO[];
  name: string;
  userGroupId: UserGroups;
}

export interface TeamGridListDTO {
  id: string;
  name: string;
  numOfMembers: number;
  selected: boolean;
  teamLeaderId: string;
  teamLeaderName: string;
  userGroupId: UserGroups;
}

export interface PendingUserList {
  email: string;
  id: string;
  invitedById: string;
  invitedByName: string;
  invitedOn: string;
  isExpired: boolean;
  lastInvitationSentById: string;
  lastInvitationSentByName: string;
  lastInvitationSentOn: string;
  selected: boolean;
  userGroupId: UserGroups;
}

export class LoginMessageDetailDTO {

  createdBy: string;
  createdByName: string;
  createdOn: string;
  displayAfter: Date;
  displayUntil: Date;
  id: string;
  isDeleted: boolean;
  modifiedBy: string;
  modifiedByName: string;
  modifiedOn: string;
  messageBody: string;
  messageTitle: string;

}

export interface LoginMessageGridDTO extends Omit<LoginMessageDetailDTO, 'messageBody'> {
  selected: boolean;
}

export interface LoginMessageDTO {
  displayAfter: Date;
  displayUntil: Date;
  messageBody: string;
  messageTitle: string;
}

export interface AttachShopifyAccountDTO {
  id: string;
  message: string;
  success: boolean;
  username: string;
}

export interface LinkEbayAccount {
  ebaySignInUrl: string;
}

export interface LinkAccount {
  message: string;
  redirectUrl: string;
  success: boolean;
}

export interface AttachEtsyAccount {
  message: string;
  success: boolean;
  username: string;
}

export interface ListingStaticsDTO {
  checked: boolean;
  color: string;
  status: ListingStatus;
  title: string;
  to: number;
  tooltip: string;
}

export interface ChartFieldDTO {
  checked: boolean;
  color: string;
  percent: number;
  title: string;
  to: number;
  tooltip: string;
}

export interface PossibleValuesDTO<T> {
  name?: string;
  value: T;
  viewValue: string;
}

export interface PossibleErrorDTO {
  description: string;
  type: ValidationErrorType;
}

export interface SelectBarOption <T> {
  checked: boolean;
  value: T;
  viewValue: string;
}

export interface ActiveUserDTO {
  email: string;
  id: string;
  name: string;
}

export interface MarketplaceListDTO<T = MarketPlaces, R = MarketplaceTypes> {
  logoUrl: string;
  value: T;
  viewValue: R;
}

export type SelectedMarketplace = {
  [key in MarketplaceTypes]?: boolean;
};

export type PossibleValueListDTO = Record<string, PossibleValuesDTO<any>[]>;

export interface SubOptionDTO<T> {
  checked: boolean;
  default?: boolean;
  value: T;
  viewValue: string;
}

export class SearchSettingsDTO {

  condition: PossibleValuesDTO<number>[];
  listingType: PossibleValuesDTO<ListingType>[];
  listingStatus: PossibleValuesDTO<ListingStatus>[];
  ebaySite: SearchSiteDTO[];
  searchAreas: SearchAreaDTO[];

}

export interface SearchAreaDTO {
  id: ShopiagoSearchAreas;
  name: string;
  value: boolean;
}

export interface SiteInfoDTO {
  fetchItemSpecific: boolean;
  globalSiteId: EbaySites;
  siteId: SiteIds;
  siteName: string;
}

export interface MarketplaceSearchDetailDTO {
  binding: string;
  condition: {
    id: number;
    name: string;
  };
  currency: CurrencyCodes;
  description: string;
  ebayItemId: string;
  highestEstimatedSoldPrice: number;
  images: AmazonImagesDTO[];
  itemIdentifers: StringObjectDTO;
  listPrice: ListPriceDTO;
  listingId: string;
  location: string;
  lowestEstimatedSoldPrice: number;
  lowestOfferPrice: LowestOfferPriceDTO;
  marketplace: MarketPlaces;
  masterListingId: string;
  optionalItemSpecifics: StringObjectDTO;
  pictureUrls: string[];
  price: number;
  priceMax: number;
  priceMin: number;
  primaryCategoryName: string;
  primaryCategorySystemId: string;
  primaryCategoryTree: CategorySearchDTO[];
  primaryCategoryTreeString: string;
  recommendedItemSpecifics: StringObjectDTO;
  requiredItemSpecifics: StringObjectDTO;
  resultPrice: number;
  secondaryCategoryName: string;
  secondaryCategorySystemId: string;
  secondaryCategoryTree: CategorySearchDTO[];
  shippingPrice: number;
  siteInfo: SiteInfoDTO;
  stockPhotoURL: string;
  title: string;
  type: ListingType;
}

export type StringObjectDTO = Record<string, string[]>;

export interface GlobalErrorDTO {
  code?: string;
  errorParameters?: string;
  field: string;
  fieldName?: string;
  message: string;
}

export interface HttpErrorDTO {
  applicationLog: unknown;
  buildLog: unknown;
  code: string;
  companyId: string;
  customLogPayload: {
    ip: string;
    requestBody: string;
    requestExecuteTime: number;
    requestLogId: string;
    requestMethod: string;
    requestURI: string;
    userAgent: string;
    userTokenData: { id: string; };
  };
  message: string;
  path: string;
  requestId: string;
  requestParameters: unknown;
  systemLog: unknown;
  timestampFormatted: string;
  userTokenExtraData: { id: string; };
}

export interface GlobalErrorsSnackBarDTO {
  message: HttpErrorDTO;
  title: string;
}

export interface GlobalMessageSnackBarDTO {
  message: string;
  messageArray: any[];
  title: string;
}

export interface ListingCreateSuccessDTO {
  listingId: string;
}

export interface GlobalMessages {
  message?: string;
  messageArray?: GlobalErrorDTO[];
}

export interface SystemJobListDTO {
  description: string;
  descriptionSimple: string;
  firedTime: number;
  isNonconcurrent: boolean;
  isRunning: boolean;
  jobName: string;
  jobSimpleName: string;
  nextFireTime: number;
  prevFireTime: number;
  scheduledTime: number;
  schedulerInstance: string;
  selected: boolean;
  triggerName: string;
  triggerState: string;
  triggerType: string;
}

export interface InventoryListTradeDTO {
  accountName: string;
  bulkScanId: string;
  companyId: string;
  createdBy: string;
  createdByName: string;
  createdOn: string;
  currencyCode: CurrencyCodes;
  fileName: string;
  id: string;
  itemLocationId: string;
  locationName: string;
  messages: string[];
  processingMethod: ProcessingMethod;
  selected: boolean;
  skusAccepted: number;
  skusProcessed: number;
  skusRecognised: number;
  skusUnrecognised: number;
  status: BulkScanStatus;
  threshold: number;
  tradeId: string;
  unitsAccepted: number;
  unitsAcceptedValue: number;
  unitsProcessed: number;
}

export interface TimelineListDTO {
  createdById: string;
  createdByName: string;
  createdOn: string;
  eventType: HistoryTypes;
  fieldList: string[];
  newValues: string[];
  oldValues: string[];
  recordId: string;
  recordName: string;
  transactionId: string;
}

export interface ChangeListDTO {
  createdById: string;
  createdByName: string;
  createdOn: string;
  newValue: string;
  oldValue: string;
  recordField: string;
  recordId: string;
  sku: string;
}

export interface EbayShippingCarrierDTO {
  availableIn: string[];
  description: string;
  value: string;
}

export interface EtsyShippingCarrierDTO {
  name: string;
  value: number;
}

export interface TrackingCompanyDTO {
  description: string;
  value: string;
}

export interface UnfulfillmentDTO {
  createdOn: string;
  currency: CurrencyCodes;
  currentLocationId: string;
  currentLocationType: LocationTypes;
  fulfillmentId: string;
  fulfillmentItemId: string;
  fulfillmentStatus: OrderFulfillmentStatus;
  imageUrl: string;
  inventoryId: string;
  listingId: string;
  marketplace: MarketPlaces;
  masterListingId: string;
  orderItemId: string;
  pickedOn: string;
  pickingStatus: PickingStatus;
  price: number;
  printedDatePackingSlip: string;
  printedDatePrintRun: string;
  quantity: number;
  secondarySku: string;
  selected: boolean;
  shippingCarrier: string;
  shopLocationAreaCode: string;
  shopLocationId: string;
  shopLocationName: string;
  sku: string;
  title: string;
  trackingNumbers: string[];
  vatPercentage: number;
}

export interface FulfillmentDTO {
  fulfillmentId: string;
  fulfillmentItems: UnfulfillmentDTO[];
}

export interface BulkIdDTO {
  targetIds: string[];
}

export interface BulkRelistDataDTO {
  bestOfferAutoAcceptPrice: number;
  edited: boolean;
  id: string;
  minimumBestOfferPrice: number;
  reservePrice: number;
  variantPriceData: BulkRelistVariantPriceDTO[];
}

export interface BulkRelistVariantPriceDTO {
  auctionStartPrice: number;
  buyItNowPrice: number;
  fixedPrice: number;
}

export interface BulkRelistRequestDTO {
  relistData: BulkRelistDataDTO[];
}

export interface DetailEvent<T> {
  event: MouseEvent;
  row: T;
}

export interface FieldNamesArrayDTO<T, R = T> {
  arrayValue?: boolean;
  cellClass?: string;
  checked: boolean;
  currencyValue?: boolean;
  dateList?: boolean;
  filterException?: boolean;
  filterValue?: GridProps<T, R>;
  id?: string;
  imageColumn?: boolean;
  justColumn?: boolean;
  justFilter?: boolean;
  minWidth: number;
  prefix?: FieldPrefix;
  secondValue?: GridProps<T, R>;
  shopSubmissionEdit?: boolean;
  skuColumn?: boolean;
  type: FieldTypes;
  userValue?: GridProps<T, R>;
  value: GridProps<T, R>;
  viewValue: string;
  width: number;
}

export type FieldPrefix = 'header_' | 'child_';

export type GridOptionFields = {
  [key in GridOptions]?: boolean;
};

export type GridOptionFrequency = {
  [key in GridOptions]?: number;
};

export type BooleanIndexDTO = Record<number, boolean>;

export interface InventoryListingHistoryDTO {
  auctionStartPrice: number;
  bestOfferAutoAcceptPrice: number;
  category: string;
  createdOn: string;
  currency: CurrencyCodes;
  isSubmitted: boolean;
  itemLocationId: string;
  itemLocationName: string;
  listedBy: string;
  listedOn: string;
  listingId: string;
  locationType: LocationTypes;
  marketplace: MarketPlaces;
  marketplaceUrl: string;
  masterListingId: string;
  minimumBestOfferPrice: number;
  price: number;
  quantity: string;
  reservePrice: number;
  soldPrice: number;
  soldPriceCurrency: CurrencyCodes;
  status: ListingStatus;
  type: 'Listing' | 'Relist';
}

export interface CharitySearchDTO {
  charityId: string;
  description: string;
  ein: string;
  logoURL: string;
  logoURLSelling: string;
  mission: string;
  name: string;
}

export interface CharitySearchRequestDTO {
  filterText: string;
}

export interface MarketplaceDefaultValuesDTO {
  ebay: EbayDefaultValuesForm;
  etsy: EtsyDefaultValuesForm;
  shopify: ShopifyDefaultValuesForm;
}

export type DefaultValueTypes = 'ebay' | 'etsy' | 'shopify';

export type DuplicateOptionTypes = 'common' | MarketplaceTypes;

export type MarketplaceDuplicateOptionsDTO = Record<DuplicateOptionTypes, SubOptionDTO<CloneOptions>[]>;

export type AccountGroup = Record<MarketplaceTypes, MarketplaceAccountGroupDTO[]>;

export interface AvailableMarketplaceDTO {
  isListed: boolean;
  type: MarketPlaces;
  value: boolean;
  viewValue: string;
}

export interface PrintErrorDTO {
  errorMessage: string;
  hasError: boolean;
}

export interface BarcodeRequestDTO {
  barcodes: string[];
}

export interface BarcodePrintRequestDTO extends BarcodeRequestDTO {
  printerName: string;
}

export interface PdfDTO {
  base64EncodedContent: string;
  optionalIdentifier: string;
}

export interface PDFRequestDTO {
  pdfList: PdfDTO[];
}

export interface PDFPrintRequestDTO extends PDFRequestDTO {
  printerName: string;
}

export interface BarcodePrintResultDTO {
  barcode: string;
  errorResult: PrintErrorDTO;
}

export interface PDFPrintResultDTO {
  errorResult: PrintErrorDTO;
  optionalIdentifier: string;
}

export interface ZPLResultDTO {
  zplCodes: string[];
}

export interface GridConfig {
  detailEnabled?: boolean;
  exportCallName: string;
  exportName: string;
  fields: FieldNamesArrayDTO<ReportListDTO>[];
  gridCallName: string;
  gridName: string;
  gridVersionNumber: number;
  variables: GridVariableMapDTO<ReportListDTO>;
}

export interface GridSortDTO<T, R = T> {
  dir: Sorting;
  prop: GridProps<T, R>;
}

export interface GridColumnSettingsDTO {
  isVisible: boolean;
  prop: string;
  viewValue: string;
}

export interface EbayPolicyDTO {
  companyId: string;
  ebayAccountId: string;
  ebaySite: EbaySites;
  id: string;
  isCIS: boolean;
  policyEbayId: string;
  policyEbayName: string;
  type: EbayPolicies;
}

export interface EbayPolicyListDTO extends EbayPolicyDTO {
  boundListingCount: number;
  ebayAccountName: string;
  selected: boolean;
}

export interface SetToCisDTO {
  filterRequest: GridRequest;
  isCis: boolean;
}

export interface SetToCisExplicitDTO {
  isCis: boolean;
  policyIds: string[];
}

export interface RoleDTO {
  checked: boolean;
  value: Roles;
  viewValue: string;
}

export interface AccountGroupDTO {
  accounts: MarketplaceAccountDTO[];
  disabled: boolean;
  name: string;
  type: MarketPlaces;
}

export interface MarketplaceAccountGroupDTO {
  accounts: AccountSelectDTO[];
  name: string;
}

export interface AccountSelectDTO {
  account: MarketplaceAccountDTO;
  shop?: EtsyShopDTO;
  site?: EbaySiteDTO;
}

export interface ListCreateDTO {
  defaultValue: string;
  possibleValues: string;
}

export interface InventoryAttributeFieldDTO {
  defaultValue: any;
  editableRoles: Roles[];
  fieldId?: string;
  id: string;
  isIndexed: boolean;
  name: string;
  order: number;
  possibleValues: any;
  requiredRoles: Roles[];
  type: FieldTypes;
  visibleRoles: Roles[];
}

export interface InventoryAttributeDTO {
  createdBy: string;
  createdByName: string;
  createdOn: string;
  ebayCategoryId: string;
  ebayCategoryName: string;
  fields: InventoryAttributeFieldDTO[];
  id: string;
  name: string;
  selected: boolean;
}

export interface InventoryAttributeRequestDTO {
  ebayCategoryId: string;
  fields: InventoryAttributeFieldDTO[];
  id: string;
}

export interface LayoutDTO {
  iconClass: HeroIconName;
  value: number;
  viewName: string;
}

export interface SearchFilterDTO {
  id: string;
  name: SearchFilters;
  type: FieldTypes;
  viewName: string;
}

export interface ListingStatisticsDTO {
  dateFrom: Date;
  dateTo: Date;
  userList: string[];
}

export interface UserStatisticsDTO {
  dateFrom: Date;
  dateTo: Date;
  site: EbaySiteDTO;
  userList: string[];
}

export interface ImageSearchDTO {
  imageData: string;
  siteList: string[];
}

export interface RegistrationDTO {
  email: string;
  invitationHash?: string;
  name: string;
  password: string;
  passwordAgain: string;
}

export interface UserInviteDTO<T, R> {
  areaCode: string;
  email: string;
  groupId: R;
  roles: T[];
  shopLocationId?: string;
  userApprovalNeeded: boolean;
  userCisOnlyListing: boolean;
  warehouseLocationId?: string;
}

export class CustomerDTO {

  buyerEmail: string;
  buyerName: string;
  companyId: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  firstOrderDate: string;
  isKeyClient: boolean;
  lastOrderDate: string;
  marketplaces: number[];
  messageCount: number;
  orderCount: number;
  orderItemCount: number;

}

export interface CustomerListDTO extends CustomerDTO {
  selected: boolean;
}

export interface SavedLoginData<T = Roles> {
  companyModules: ModuleTypes[];
  expiresIn: number;
  isImpersonate: boolean;
  itemLocationModules: ModuleTypes[];
  loginTime: number;
  roles: T[];
  token: string;
  userId: string;
  websocketUser: string;
}

export interface ImageUploadRequestDTO {
  id?: string;
  imageData: string;
  imageName: string;
  tags?: TagDTO[];
}

export interface UpdateInventoryDTO {
  description?: string;
  isJoblot?: boolean;
  name?: string;
  shopLocationId?: string;
}

export interface UpdateInventoryVariantDTO {
  giftAidNumber?: string;
  hasGiftAidNumberChanged?: boolean;
  hasToteBoxIdChanged?: boolean;
  toteBoxId?: string;
}

export interface MassEditRequestDTO {
  headerRowList: InventoryListDTO[];
  updateInventoryDTO: UpdateInventoryDTO;
  updateInventoryVariantDTO: UpdateInventoryVariantDTO;
}

export interface SKUSequenceDTO {
  companyId: string;
  startValue: number;
}

export class DefaultValuesDTO {

  categoryId: string;
  createdBy: string;
  createdOn: string;
  defaultValues: string;
  id: string;
  marketplace: MarketPlaces;
  modifiedBy: string;
  modifiedOn: string;
  site: ListingSites;
  shopId: string;

}

export interface DefaultValuesListDTO extends DefaultValuesDTO {
  categoryName: string;
  createdById: string;
  createdByName: string;
  modifiedById: string;
  modifiedByName: string;
  selected: boolean;
  shopName: string;
  status: DefaultValueStatus;
}

export type GridVariableMapDTO<T, R = T, E extends GridProps<T, R> = null> = {
  [P in GridProps<T, R>]?: CamelToSnakeCase<P, E>;
};

export type CamelToSnakeCase<S extends string, E extends string = null> =
  S extends E ? string : S extends `${infer T}${infer U}` ?
    `${T extends Exclude<Capitalize<T>, '1' | '2' | '3'> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}` : S;

export type GridProps<T, R = T> = ExtractKey<T> | ExtractKey<R>;

export type ColumnDisplayDTO<T, R = T> = (keyof T | keyof R)[];

export type PossibleValueMapDTO<T, R = T> = {
  [P in keyof T | keyof R]?: PossibleValuesDTO<any>[];
};

export type LocationActionResponse = string[] | string | boolean;

export interface CloneSettingsDTO<T> {
  subOptions: SubOptionDTO<T>[];
  version: number;
}

export interface MagicStringDTO {
  keyword: string;
  name: string;
  value: string;
}

export class ShipmentCommonDTO {

  companyId: string;
  contents: ShipmentContents;
  customsStatus: CustomsStatus;
  deliveryDate: string;
  id: string;
  isLabelPrinted: boolean;
  isVoided: boolean;
  itemLocationId: string;
  labelStatus: ShipmentLabelStatus;
  manifestStatus: ManifestStatus;
  nonDelivery: ShipmentNonDelivery;
  shipDate: string;
  shipToCity: string;
  shipToCompany: string;
  shipToName: string;
  shipToPhone: string;
  shipToPostalcode: string;
  shipToState: string;
  shipToStreet1: string;
  shipToStreet2: string;
  shipToStreet3: string;
  shipmentStatus: ShipmentStatus;
  shippingService: string;
  trackingNumber: string;
  trackingStatus: ShipmentTrackingStatus;
  usingCustoms: boolean;
  usingTaxId: boolean;
  voidedDate: string;

}

export interface ShipmentListDTO extends ShipmentCommonDTO {
  orderIds: string[];
  selected: boolean;
}

export class ShipmentDetailDTO extends ShipmentCommonDTO {

  confirmation: ShipmentConfirmation;
  itemCustoms: CustomsDTO[];
  itemTaxIds: TaxIdDTO[];
  orderIds: string[];
  orderStatus: OrderFulfillmentStatus;
  shipFromCity: string;
  shipFromCompany: string;
  shipFromCountry: string;
  shipFromName: string;
  shipFromPhone: string;
  shipFromPostalcode: string;
  shipFromState: string;
  shipFromStreet1: string;
  shipFromStreet2: string;
  shipFromStreet3: string;
  shipToCountry: string;
  shipmentPackages: ShipmentPackageDTO[];

}

export interface ShipmentPackageDTO {
  depth: number;
  dimensionUnit: ShipmentDimensionUnit;
  height: number;
  packageCode: string;
  weight: number;
  weightUnit: ShipmentWeightUnit;
  width: number;
}

export interface ShipEngineAccountDTO {
  apiKey: string;
  service?: string;
}

export interface CybertillAccountDTO {
  service?: string;
  token: string;
  username: string;
}

export interface BatchCreateDTO {
  orderIds: string[];
  orderItemIds: string[];
}

export interface TaxIdDTO {
  identifier: string;
  identifierType: TaxIdTypes;
  issuingAuthority: string;
  taxableEntityType: TaxableEntityTypes;
}

export interface BatchDTO {
  abbreviatedName: string;
  contents: ShipmentContents;
  itemTaxIds: TaxIdDTO[];
  nonDelivery: ShipmentNonDelivery;
  notQualifiedOrderItems: ShippingOrderItem[];
  orderItems: ShippingOrderItem[];
  packages: ShipmentPackageDTO[];
  recipientAddress1: string;
  recipientAddress2: string;
  recipientCity: string;
  recipientCountry: string;
  recipientEmailAddress: string;
  recipientName: string;
  recipientPhoneNumber: string;
  recipientPostalCode: string;
  recipientState: string;
  senderAddress1: string;
  senderAddress2: string;
  senderCity: string;
  senderCountry: string;
  senderItemLocationId: string;
  senderName: string;
  senderPhoneNumber: string;
  senderPostalCode: string;
  senderState: string;
  shippingCost: number;
  shippingService: string;
  useCustoms: boolean;
  useTaxId: boolean;
}

export interface ParcelCreateDTO {
  carrierCode: string;
  confirmation: number;
  contents: ShipmentContents;
  itemTaxIds: TaxIdDTO[];
  nonDelivery: ShipmentNonDelivery;
  notQualifiedOrderItems: ShippingOrderItem[];
  orderItems: ShippingOrderItem[];
  packages: ShipmentPackageDTO[];
  recipientAddress1: string;
  recipientAddress2: string;
  recipientCity: string;
  recipientCountry: string;
  recipientEmailAddress: string;
  recipientIsCompany: boolean;
  recipientName: string;
  recipientPhoneNumber: string;
  recipientPostalCode: string;
  recipientState: string;
  senderAddress1: string;
  senderAddress2: string;
  senderCity: string;
  senderCountry: string;
  senderItemLocationId: string;
  senderName: string;
  senderPhoneNumber: string;
  senderPostalCode: string;
  senderState: string;
  serviceCode: string;
  shipDate: Date;
  shippingCost: number;
  shippingService: string;
  useCustoms: boolean;
  useTaxId: boolean;
}

export interface CustomsDTO {
  amount: number;
  countryOfOrigin: string;
  currency: ShipmentCurrency;
  description: string;
  harmonizedTariffCode: string;
  itemId: string;
  quantity: number;
  sku: string;
  skuDescription: string;
}

export interface ShippingOrderItem {
  itemCustoms: CustomsDTO;
  orderItemId: string;
  orderItemName: string;
}

export interface CarriersDTO {
  code: string;
  estimateCurrency: ShipmentCurrency;
  estimatePriceErrors: string[];
  internalId: string;
  maxEstimatedShippingCost: number;
  minEstimatedShippingCost: number;
  name: string;
  packages: Package[];
  services: Service[];
  shipEngineId: string;
}

export interface Package {
  code: string;
  estimatedShippingCost: number;
  id: string;
  name: string;
}

export type IterableObject<T> = T extends Iterable<infer U> ? U : never;

export interface Service {
  code: string;
  hasMultiPackageSupport: boolean;
  isDomestic: boolean;
  isInternational: boolean;
  maxEstimatedShippingCost: number;
  minEstimatedShippingCost: number;
  name: string;
}

export interface RoleRequestDTO<T> {
  areaCode: string;
  basketAccepted?: boolean;
  roles: T[];
  shopLocationId?: string;
  userApprovalNeeded: boolean;
  userCisOnlyListing: boolean;
  warehouseLocationId?: string;
}

export interface RoleResponseDTO<T> {
  areaCode: string;
  basketAccepted: boolean;
  roles: T[];
  shopLocationId: string;
  userApprovalNeeded: boolean;
  userCisOnlyListing: boolean;
  warehouseLocationId: string;
}

export interface InventoryIdsDTO {
  inventoryIds: string[];
}

export interface ImageIdsDTO {
  id: string;
}

export interface ImageExceptedIdsDTO {
  ids: ImageIdsDTO[];
}

export interface EbayRegistrationDTO {
  ebayOauth2AuthorizationDTO: EbayOauth2AuthorizationDTO;
  password: string;
  passwordAgain: string;
}

export interface EbayRegistrationSuccessDTO {
  isRegistrationSuccess: boolean;
  message: string;
  username: string;
}

export interface EbayOauth2AuthorizationDTO {
  code: string;
  expiresIn: number;
  state: string;
}

export interface EbayTrackingInfoDTO {
  shippingCarrier: string;
  trackingNumber: string;
}

export interface FulfillPartiallyDTO {
  orderId: string;
  orderItemIds: string[];
}

export interface ShopifyFullfilmentResponse {
  fulfillments: ShopifyFulfillment[];
  orderId: string;
}

export interface ShopifyFulfillment {
  notifyCustomer: boolean;
  orderItemFulfillments: OrderItemFulfillment[];
  trackingInfo: {
    trackingCompany: string;
    trackingNumbers: string[];
    trackingUrls: string[];
  };
}

export interface OrderItemFulfillment {
  id: string;
  quantity: number;
}

export interface ShopifyTrackingInfoDTO {
  trackingCompany: string;
  trackingNumbers: string[];
  trackingUrls: string[];
}

export interface EtsyTrackingInfoDTO {
  carrierNumValue: number;
  sendBCC: boolean;
  trackingCode: string;
}

export interface PickedRequestDTO {
  excludedIds: string[];
  secondaryIds: string[];
}

export interface AutoFilterData {
  accounts: AccountListDTO;
  cisShopProducts: InventoryListDTO;
  imagegallery: ImageGalleryDTO;
  listings: ListingListAdvancedDTO;
  notification: NotificationListDTO;
  orders: OrderListDTO;
  products: InventoryListDTO;
  shopProducts: InventoryListDTO;
}

export interface MarketplaceSearchDataDTO {
  condition: string;
  itemId: string;
  marketplace: MarketPlaces;
  siteId: SiteIds;
}

export interface UIErrorParamDTO<T, R = T> {
  errorMessage: string;
  errorType: ValidationErrorType;
  fieldName: string;
  fieldValue: GridProps<T, R>;
  formArray?: boolean;
  groupName?: ExtractKey<T>;
  indexedSubGroup?: boolean;
  subGroupName?: ExtractKey<T>;
}

export interface ShopManagersToShopLocationDTO {
  shopLocationID: string;
  userID: string;
}

export interface BookInDTO {
  acceptAfterBookIn: boolean;
  rejectAfterBookIn: boolean;
  rejectDTO?: ProductRejectDTO;
  stockLocationId: string;
  toteBoxId: string;
}

export interface BookInDialogResponse {
  data: BookInDTO;
  printType: PrintType;
}

export interface BookInSaveResponse {
  printType: PrintType;
  response: boolean | HttpErrorResponse;
}

export interface BookInRequestDTO extends BulkIdDTO {
  bookInData: BookInDTO;
}

export interface SendToWarehouseRequestDTO extends BulkIdDTO {
  transferBoxId: string;
}

export interface RepriceRequestDTO extends BulkIdDTO {
  repriceRuleGroupIds: string[];
}

export interface ProductRejectDTO {
  reason: string;
  rejectType: ProductRejectReason;
}

export interface SoldItemSummary {
  averageSalePrice: number;
  currency: CurrencyCodes;
  marketplace: MarketPlaces;
  orderItemAmount: number;
  site: ListingSites;
  siteName: string;
  summarisedOrderItemPrice: number;
}

export interface DeviceChangeEventDTO {
  device: MediaDeviceInfo;
  mirrored: boolean;
}

export interface DefaultCameraListDTO {
  deviceId: string;
  mirrored: boolean;
}

export interface PriceSuggestionDTO {
  currency: CurrencyCodes;
  price: number;
}

export interface EbayPriceSuggestionDTO {
  Auction: PriceSuggestionDTO;
  FixedPrice: PriceSuggestionDTO;
}

export interface MarketplaceDetailEventDTO {
  fillData: ListingFillDTO[];
  result: MarketplaceSearchResultDTO;
}

export interface SearchItemSelectEventDTO {
  item: MarketplaceSearchResultDTO;
  site: SiteIds;
}

export interface RouteIDParam {
  id: string;
}

export interface FormParams extends RouteIDParam {
  event: UserEvent;
}

export interface LocationFormParams extends RouteIDParam {
  event: UserEvent;
  target: UserEvent;
}

export interface ReportParam {
  reportName: Reports;
}

export interface MarketplaceDetailParam {
  detail: string;
}

export interface GridTabParams<M = MenuPages> extends RouteIDParam {
  type: M;
}

export interface ListingDetailParams extends RouteIDParam {
  type: LoadFrom;
}

export interface ListingCreateParams extends RouteIDParam {
  account: LoadFrom;
  condition: string;
  event: UserEvent;
  marketplace: LoadFrom;
  siteId: SiteIds;
  source: LoadFrom;
}

export interface ProductCreateParams extends FormParams {
  condition: string;
  marketplace: LoadFrom;
  siteId: SiteIds;
  source: LoadFrom;
}

export interface MessagesParams extends RouteIDParam {
  folderName: string;
  source: UserEvent;
}

export interface ShippingMethodParams extends RouteIDParam {
  accountId: string;
  marketplace: LoadFrom;
}

export interface DocsParams {
  call: string;
  service: string;
}

export interface RegistrationParams {
  inviteToken: string;
  invitedEmail: string;
}

export interface MenuItem {
  active?: boolean;
  alias: string;
  operation: RelativeValues;
  text: string;
}

export interface DateRangePickerResponseDTO {
  activeRange: RelativeValues;
  from: Date;
  to: Date;
}

export interface EtsyShippingDimension {
  account: MarketplaceAccountDTO;
  disabled: boolean;
  group: FinalFormGroup<ShippingDimensionGroup>;
}

export interface EbayPricing {
  currency: CurrencyCodes;
  defaultValues: EbayDefaultValuesForm;
  disabled: boolean;
  ebayPaymentOptedIn: boolean;
  group: FinalFormGroup<PricingGroup>;
  isVariantListing: boolean;
  listingData: EbayDetailUiDTO;
  paymentMethodAllowed: boolean;
  type: ListingType;
}

export interface PriceCheckData {
  bestOffer: string;
  minimumBestOffer: string;
}

export interface EbayShipping {
  account: MarketplaceAccountDTO;
  allowEBayCashOnPickup: boolean;
  currency: CurrencyCodes;
  disabled: boolean;
  domesticShipping: EbayShippingMethodSelectorListDTO[];
  ebayPaymentOptedIn: boolean;
  flatRates: boolean;
  globalShipping: boolean;
  group: FinalFormGroup<EbayShippingMethodGroup>;
  internationalShipping: EbayShippingMethodSelectorListDTO[];
  isCisOnly: boolean;
  paymentMethodAllowed: boolean;
  site: EbaySiteDTO;
}

export interface EbayShippingResponse {
  domesticShipping: EbayShippingMethodSelectorListDTO[];
  group: FinalFormGroup<EbayShippingMethodGroup>;
  internationalShipping: EbayShippingMethodSelectorListDTO[];
  paymentMethodAllowed: boolean;
}

export interface EbayReturnPolicy {
  categoryReturnsWithinList: EbayReturnsWithinDTO[];
  disabled: boolean;
  group: FinalFormGroup<ReturnPolicyFormGroup>;
  site: EbaySiteDTO;
}

export interface EbayPoliciesDialog {
  account: MarketplaceAccountDTO;
  disabled: boolean;
  group: FinalFormGroup<EbayPolicyGroup>;
  isCisOnly: boolean;
  site: EbaySiteDTO;
}

export interface EbayItemSpecifics {
  disabled: boolean;
  group: FormGroup<ItemSpecificsGroup>;
}

export interface EtsyOtherOptions {
  account: MarketplaceAccountDTO;
  disabled: boolean;
  group: FinalFormGroup<EtsyOtherGroup>;
  selectedShop: EtsyShopDTO;
}

export interface EbayWeightsAndDimensionsOptions {
  disabled: boolean;
  group: FinalFormGroup<ShippingPackageDetailsGroup>;
  packages: EbayShippingServicePackageDTO[];
}

export interface VariantCreateDTO {
  ebayItemSpecifics: SpecificDTO[];
  selectedMarketplace: SelectedMarketplace;
  variantSpecifics: StringObjectDTO;
}

export interface VariantCreateResponseDTO {
  specificsPairs: StringObjectDTO[];
  variantSpecifics: StringObjectDTO;
}

export interface VariantDataCreateDTO {
  disabled: boolean;
  giftAidValidationPattern: GiftAidRulePatternDTO;
  inventoryVariants: InventoryVariantListDTO[];
  isDuplicate: boolean;
  isGiftAidMandatory: boolean;
  isRevise: boolean;
  isToteBoxIdMandatory: boolean;
  itemId: string;
  rowIndex: number;
  shopLocationId: string;
  type: VariantDataTypes;
  variants: FinalFormArray<VariantArray>;
}

export interface VariantDataDialogResponse {
  inventoryVariants: InventoryVariantListDTO[];
  variants: FinalFormArray<VariantArray>;
}

export class MatSelectChange<T> extends AngularMatSelectChange {

  override value: T;

}

export interface ReplySettingsDTO {
  displayToPublic: boolean;
  emailCopyToSender: boolean;
  questionType: EbayMessageQuestionType;
}

export interface AttributeCreatorResponse {
  attribute: string;
  value: string;
}

export interface ListingSettingsDTO {
  autoCancelAfterDays: number;
  autoRelistLimit: number;
  autoRenewal: boolean;
  createNew: boolean;
  repriceRule: string;
  restockData: FinalFormArray<RestockVariant>;
  schedule: string;
  type: PossibleValuesDTO<ListingUploadType>;
}

export interface StartSellingResponse {
  barcodeScan: boolean;
  searchValue: string;
}

export interface SKUSearchResponse {
  searchResult: InventoryListDTO[];
  searchValue: string;
}

export interface ReturnPolicyResponseDTO {
  refundOption: RefundOption;
  returnAccepted: BooleanOptions;
  returnPolicyDetails: string;
  returnsWithin: ReturnedWithin;
  shippingCostPaidBy: PaidBy;
}

export interface ItemSpecificsDataDTO {
  filteredRecommendations: string[];
  helpText: string;
  index: number;
  maxValues: number;
  minValues: number;
  multipleSelect: boolean;
  name: string;
  required: boolean;
  selectionMode: ItemSpecificType;
  value: any;
  valueRecommendations: string[];
}

export type SiteKeys = EbaySites | 'UK' | 'Shopiago';

export type SiteIds = `${SiteId}` | 'UK';

export type SearchMarketplaceDTO = {
  [key in SiteKeys]?: boolean;
};

export interface MarketplaceSearchSettings {
  ebaySearchFilterGroup: MarketplaceChipSearchFilterDTO;
  marketplaces: SearchMarketplaceDTO;
  version: number;
}

export interface ShopiagoChipSearchFilterDTO {
  listingStatus: ListingStatus;
  searchAreas: string;
}

export interface ShopiagoSearchSettings {
  searchAreas: SearchAreaDTO[];
  shopiagoSearchFilterGroup: ShopiagoChipSearchFilterDTO;
  version: number;
}

export interface BillingReportGroupDTO {
  key: any;
  value: {
    commission: number;
    companyId: string;
    companyName: string;
    date: Date;
    marketplace: string;
    refundedAmount: number;
    total: number;
  }[];
}

export interface PostcodeValidateDTO {
  result: boolean;
  status: number;
}

export interface ShopiagoStatDTO {
  listingWithSameIdentifierCount: number;
  sameCategoryListingCount: number;
  sameCategorySales: number;
}

export interface QuickLinkDTO {
  access: boolean;
  function: QuickLinks;
  icon: HeroIconName;
  title: string;
}

export interface DocsSearchDTO {
  description: string;
  name: string;
  url: string;
}

export interface WhenMadeDTO {
  name: string;
  when: PossibleValuesDTO<WhenMade>[];
}

export interface ListingTemplateTagDTO {
  name: ListingTemplateTagName;
  tag: string;
}

export interface EnvironmentDTO {
  production: boolean;
  sentryDns: string;
}

export interface AppEnvironmentDTO extends EnvironmentDTO {
  ebayRegistration: boolean;
  linkedinPartnerId: string;
  maintenance: boolean;
}

export interface SelectedMessageDTO {
  folderName: string;
  messageId: string;
  pageNumber: string;
}

export class OrderDetailCommonDTO {

  orderId: string | number;
  paymentMethod: PaymentMethods;
  currency: CurrencyCodes;
  subtotal: number;
  shippingService: string;
  shippingCost: number;
  totalPrice: number;
  orderTags: TagDTO[];

}

export interface FormFillEvent {
  detailData: MarketplaceSearchDetailDTO;
  fillData: ListingFillDTO[];
}

export interface ExternalSearchEvent {
  historySave: boolean;
  keyword: string;
}

export interface SaveNameDialogDTO {
  companyWideEnabled?: boolean;
  confirmButton: string;
  confirmNewButton?: string;
  defaultName?: string;
  description: string;
  isCompanyWide?: boolean;
  maxLength: number;
  showSaveNew?: boolean;
  title: string;
}

export interface SaveNameDialogResponseDTO {
  isCompanyWide: boolean;
  name: string;
  saveNew: boolean;
}

export interface AddImageDialogDTO {
  images: ImageDTO[];
}

export interface LeaveSiteConfirmDialogDTO {
  isDialog?: boolean;
  message: string;
}

export interface ListingCancelDialogDTO {
  isCulling: boolean;
  listings: ListingCancelDTO[];
}

export interface CategorySearchDialogDTO {
  ebaySiteValue: ListingSites;
  query: string;
}

export interface UserInviteDialogDTO {
  id: string;
}

export interface ErrorDialogDTO {
  installer: string;
  message: string;
  printError?: boolean;
  title: string;
}

export interface ProductDialogDTO {
  id: string;
}

export interface AttachAccountDialog {
  accountChange?: boolean;
  cybertillAccountConnected: boolean;
  defaultConnect?: MarketPlaces | ApiTypes;
  shipEngineConnected: boolean;
}

export interface TemplateBrowserDialogDTO {
  titleMessage: string;
}

export interface TemplateBrowserDialogResponse {
  options: MarketplaceDuplicateOptionsDTO;
  template: AllTemplateListDTO;
}

export interface TagDialogDTO {
  filterRequest?: GridRequest;
  ids: string[];
  type: TagTypes;
}

export interface LocationConfirmDialogDTO {
  selectedLocation?: string;
  type: LocationDialogTypes;
}

export interface ListCreateDialogDTO {
  defaultValue: any;
  possibleValues: any;
}

export interface IntervalEditorDialogDTO {
  qrtzJobName: string;
  runInterval: number;
  scheduledRunTime: string;
  startHour: number;
}

export interface BookInDialogDTO {
  isToteBoxIdMandatory: boolean;
}

export interface CaptureImagesDialogDTO {
  filters?: FilterGroupDTO<any, any, any>[];
  itemId: string;
  savedImages: ImageDTO[];
  sku: string;
}

export interface FolderBrowserDialogDTO {
  allFolder: EbayFolderDTO[];
  selectedFolder: string;
}

export interface ListingDialogDTO {
  masterListingId: string;
  type: LoadFrom;
}

export interface OrderDialogDTO {
  id: string;
}

export interface OrderCancelDialogDTO {
  marketplace: MarketPlaces;
  orderId: string;
}

export interface OrderCancelDTO {
  cancelReason: number;
  notifyCustomer: boolean;
}

export interface EbayOrderCancelEligibilityDTO {
  ebayCancelReasonList: EbayOrderCancelReason[];
  ebayOrderId: string;
  failureReasons: string[];
  isEligible: boolean;
}

export interface AttributeDialogDTO {
  hasDetail: boolean;
}

export interface StatusSelectorDialogDTO {
  needCircle?: boolean;
  selectedStatus: MessageStatus | PickingStatus;
  statuses: PossibleValuesDTO<MessageStatus | PickingStatus>[];
  title: string;
}

export interface RepriceRuleAttachDialogDTO extends BulkIdDTO {
  listingType: ListingType;
}

export interface ListOrSaveDialogDTO {
  autoCancelAfterDays: number;
  autoRelistLimit?: number;
  autoRenewal: boolean;
  createNewListingEnabled: boolean;
  enableRelist: boolean;
  isInstantListingEnabled: boolean;
  isRelocationMandatoryOnListing: boolean;
  isRevise: boolean;
  isToteBoxIdMandatory: boolean;
  isVariant: boolean;
  itemId: string;
  listingData?: EbayListingDTO;
  listingType: ListingType;
  marketplaces: SelectedMarketplace;
  needDefaultRepriceRule: boolean;
  repriceRule: string;
  restockData: FinalFormArray<RestockVariant>;
  schedule: string;
  target: UserEvent;
  type: PossibleValuesDTO<ListingUploadType>;
  variants: FinalFormArray<VariantArray>;
}

export interface ClientDetailDialogDTO {
  id: string;
}

export interface TrackingInfoOrderItemDTO {
  id: string;
  quantity: number;
  title: string;
}

export interface TrackingInfoDialogDTO {
  fulfillOrder: boolean;
  marketplace: MarketPlaces;
  orderId: string;
  orderItems?: TrackingInfoOrderItemDTO[];
}

export interface ProductDetailDTO {
  event: UserEvent;
  id: string;
  mergeItems?: InventoryListDTO[];
}

export interface DateRangePickerDates {
  from: Date;
  to: Date;
}

export interface DateRangePickerOptions {
  activeRange?: RelativeValues;
  date?: DateRangePickerDates;
}

export interface DayDTO {
  date: Date;
  day: number;
  firstMonthDay: boolean;
  from: boolean;
  isWithinRange: boolean;
  lastMonthDay: boolean;
  to: boolean;
  today: boolean;
  visible: boolean;
  weekday: number;
}

export interface MassEditDialogDTO {
  editType: 'BulkEdit' | 'WarehouseLocationChange';
  gridRequest: GridRequest;
  possibleValues: PossibleValueListDTO;
  selectedItems: InventoryListDTO[];
}

export interface ArrayConfirmDialogDTO {
  boundListingArray?: BoundListingDTO[];
  inventoryArray?: InventoryListDTO[];
  message: string;
}

export interface DataSyncDialogDTO {
  marketplace: MarketPlaces;
  username: string;
}

export interface PDFExportBarDTO {
  isItem: string;
  request?: PdfExportDTO;
  type: OrderPrintType;
}

export interface ImageUploadBarDTO {
  files?: UploadFile[];
  processedFiles?: ImageUploadRequestDTO[];
  tags?: TagDTO[];
}

export interface RejectReasonDTO {
  reason: string;
  rejectType: ProductRejectReason | ListingRejectReason;
}

export interface UploadFile {
  fileIndex: number;
  id: string;
  lastModifiedDate: Date;
  name: string;
  nativeFile?: File;
  size: number;
  type: string;
}

export interface UploadOutput {
  file?: UploadFile;
  type: UploadTypes;
}

export interface UploadInput {
  data?: Record<string, string | Blob>;
  fieldName?: string;
  file?: UploadFile;
  fileIndex?: number;
  id?: string;
  includeWebKitFormBoundary?: boolean;
  method?: string;
  type: UploadTypes;
  url?: string;
  withCredentials?: boolean;
}

export interface ReportDetailsDialogDTO {
  category: string;
  currency: CurrencyCodes;
  reportFilters: FilterGroupDTO<ReportListDTO>[];
  shopLocationId: string;
  shopLocationName: string;
}

export interface MassBookInRequest extends MassGridRequest {
  bookInData: BookInDTO;
}

export interface MassSendToWarehouseRequest extends MassGridRequest {
  transferBoxId: string;
}

export interface ItemSpecificsArrayDTO<T> {
  id: keyof T;
  title: string;
  type: ItemSpecificsTypes;
}

export interface MultipleSelectValueDTO {
  checked: boolean;
  value: string;
}

export interface ChartTypeDTO {
  icon: HeroIconName;
  title: string;
  type: ChartType;
}

export interface LastGridSearchDTO<G, U> {
  data: GridResponse<any>;
  filter: FilterGroupDTO<any>[];
  gridName: G;
  indeterminate: boolean;
  savedFilter: GridFilterDTO<any>;
  scrollTop: number;
  selected: any[];
  selectedAll: boolean;
  selectedPageSize: number;
  sorts: GridSortDTO<any>[];
  url: addPrefix<U, '/'>;
}

export interface ShopSectionDTO {
  activeListingCount: number;
  etsyAccountId: string;
  etsyId: number;
  etsyShopId: number;
  id: string;
  rank: number;
  title: string;
}

export interface ShopSectionDialogDTO {
  etsyAccountId: string;
  shopId: number;
  shopSections: ShopSectionDTO[];
  userId: number;
}

export interface EtsyShopDTO {
  acceptsCustomRequests: boolean;
  etsyAccountId: string;
  etsyShopId: number;
  etsyUserId: number;
  isVacation: boolean;
  shopCurrency: CurrencyCodes;
  shopId: string;
  shopName: string;
}

export interface CommunicationBoardListDTO {
  attachmentId: string;
  attachmentName: string;
  createdOn: string;
  id: string;
  imageUrls: string[];
  isAccountOwner: boolean;
  listingMarketplaces: MarketPlaces[];
  listingRecordType: ListingSaveType;
  message: string;
  parentId: string;
  poll: PollDTO;
  replies: CommunicationBoardListDTO[];
  sendingUserName: string;
  type: CommunicationBoardTypes;
  userId: string;
}

export interface BoardImageDTO {
  id: string;
  imageData: string;
}

export interface PollAnswerDTO {
  id: string;
  order: number;
  text: string;
  votedByCurrentUser: boolean;
  votes: number;
}

export interface PollDTO {
  answers: PollAnswerDTO[];
  id: string;
  question: string;
}

export interface CommunicationBoardCreateDTO {
  attachmentId: string;
  id: string;
  images: BoardImageDTO[];
  message: string;
  parentId: string;
  poll: PollDTO;
  type: CommunicationBoardTypes;
}

export interface LastBoardPositionDTO {
  id: string;
  markDate: string;
}

export interface LastBoardPositionRequestDTO {
  attachmentId: string;
  id: string;
  type: CommunicationBoardTypes;
}

export interface QuillMentionDTO {
  id: string;
  type: CommunicationBoardTagTypes;
  value: string;
}

export interface MentionDTO {
  id: string;
  name: string;
  type: CommunicationBoardTagTypes;
}

export interface UserRoleDTO {
  roleCount: number;
  roleText: string;
}

export interface UserAssignDialogDTO {
  assignedUserId: string;
  userList: ShopiagoUserListDTO[];
}

export interface RepriceGroupListDTO {
  afterDays: number;
  fieldName: RepriceFields;
  id: string;
  onRelistNumber: number;
  repriceTextValue: string;
  repriceType: RepriceTypes;
  repriceValue: number;
}

export class RepriceRuleDTO {

  id: string;
  isDefault: boolean;
  listingType: ListingType;
  name: string;
  repriceRuleList: RepriceGroupListDTO[];

}

export interface RepriceRuleViewDTO {
  panelWidth: number;
  rules: RepriceRuleDTO;
}

export interface RepriceRuleListDTO extends RepriceRuleDTO, Audit2 {
  selected: boolean;
  usageCount: number;
}

export interface ReportChartRequest extends Interval {
  companyIds?: string[];
  currency?: CurrencyCodes;
  shopLevelFilter?: boolean;
}

export interface ShipmentSettings {
  defaultDepth: number;
  defaultDimensionUnit: ShipmentDimensionUnit;
  defaultHeight: number;
  defaultWeight: number;
  defaultWeightUnit: ShipmentWeightUnit;
  defaultWidth: number;
}

export interface ProductValidationDTO {
  confirmResponse: boolean;
  productListed: boolean;
}

export interface GridRequestWithIntervals extends Interval {
  conditionType?: number;
  ebayListingTypes?: number[];
  ids?: string[];
  paymentStatus?: number[];
  request: GridRequest;
  shopIds?: string[];
  shopLevelFilter?: boolean;
}

export interface ReportDetailsRequest extends Interval {
  conditionType?: number;
  currency: CurrencyCodes;
  ebayListingTypes?: number[];
  paymentStatus?: number[];
  request: GridRequest;
  rootCategory?: string;
  shopLevelFilter?: boolean;
  shopLocationId?: string;
}

export interface GridRequestGiftAidIntervals {
  conditionType?: number;
  ebayListingTypes?: number[];
  orderDateEnd?: Date;
  orderDateStart?: Date;
  paymentDateEnd?: Date;
  paymentDateStart?: Date;
  request: GridRequest;
  shopIds?: string[];
  shopLevelFilter?: boolean;
}

export interface ListingReportGridRequest extends Interval {
  filteredTeamIds?: string[];
  filteredUserIds?: string[];
  id?: string;
  request: GridRequest;
  shopIds?: string[];
  shopLevelFilter?: boolean;
}

export interface MassGridRequest {
  excludedIds: string[];
  filterRequest: GridRequest;
}

export interface MassGridRequestWithData extends MassGridRequest {
  secondaryIds: string[];
}

export interface PdfExportDTO {
  ebayExceptions: string[];
  etsyExceptions: string[];
  filterRequest: GridRequest;
  filteredOrderIds?: string[];
  filteredOrderItemIds?: string[];
  orderIDsWhichHaveNotPrintedItems?: string[];
  shopifyExceptions: string[];
  timezone: string;
}

export interface GridConfigDTO<T, R = T> {
  fieldNames: FieldNamesArrayDTO<T, R>[];
  gridOptionFrequency: GridOptionFrequency;
  pageSize: number;
  sort: GridSortDTO<T, R>[];
  versionNumber: number;
}

export interface GridConfigResponse<T, R> {
  gridData: GridConfigDTO<T, R>;
  updated: boolean;
}

export interface CullingDTO {
  endingReasonCodesByMarketplace: {
    [p in MarketPlaces]: Record<string, ListingDeleteReason>;
  };
}

export interface SearchRoles {
  listingCreate: boolean;
  product: boolean;
}

export interface OrderNotPrintedIDsDTO {
  excludedIds: string[];
  secondaryIds: string[];
}

export interface ShipmentNotPrintedIDsDTO {
  printableIds: string[];
  shipmentIds: string[];
}

export interface FlatRateDTO {
  discountId: string;
  discountNameType: DiscountNameType;
  discountProfileId: string;
  discountProfileName: string;
  eachAdditionalAmount: number;
  eachAdditionalAmountOff: number;
  eachAdditionalPercentOff: number;
  id: string;
  periodType: number;
  site: ListingSites;
}

export interface FlatRateListDTO {
  currency: CurrencyCodes;
  discountId: string;
  discountNameType: DiscountNameType;
  discountProfileId: string;
  discountProfileName: string;
  ebayAccountId: string;
  ebayAccountName: string;
  flatShippingDiscountId: string;
  isDefaultDomestic: boolean;
  isDefaultInternational: boolean;
  selected: boolean;
  site: ListingSites;
  value: number;
}

export interface AddImageDialogResponseDTO {
  loadedImages?: UploadFile[];
  selectedImages?: ImageListDTO[];
  tags?: TagDTO[];
}

export interface BoundListingDTO {
  listingId: string;
  listingName: string;
  listingStatus: ListingStatus;
  marketplace: MarketPlaces;
  masterListingId: string;
  recordType: ListingSaveType;
  type: ListingType;
}

export interface ConfirmDialogDTO {
  customTitle?: string;
  value: string;
  yesOrNo?: boolean;
}

export interface ResponseMessageData {
  customMessage?: string;
  errorMessage?: string;
  message?: string;
}

export interface MessageResponseContext {
  context: HttpContext;
}

export interface ImageViewerDialogDTO {
  images: ImageListDTO[] | ImageDTO[] | ImageGalleryDTO[];
  selected: number;
}

export interface PrinterSelectorDTO {
  defaultDevice?: Device;
  defaultPrinter?: string;
  devices?: Device[];
  printers?: string[];
}

export interface ImpersonateDialogDTO {
  token: string;
}

export interface FormFillDialogDTO {
  isProduct?: boolean;
  sameSite?: boolean;
  stockPhotoExists?: boolean;
}

export interface BarcodeScannerDialogDTO {
  formats: BarcodeFormat[];
}

export interface ManifestDTO {
  carrierId: string;
  createdBy: string;
  createdOn: string;
  creationDate: string;
  downloadUrl: string;
  errors: string[];
  formId: string;
  id: string;
  isPrinted: boolean;
  labelIds: string[];
  manifestId: string;
  shipmentDate: string;
  shipmentIds: string[];
  shipments: number;
  submissionId: string;
  warehouseId: string;
}

export interface ReportListDTO {
  activeListings: number;
  allItemQuantityCount: number;
  areaCode: string;
  averageSalePrice: number;
  buyingFormat: ListingType;
  cancelledItems: number;
  condition: ConditionTypes;
  countAccepted: number;
  countCisListingRequest: number;
  countInStore: number;
  countInTransit: number;
  countInWarehouse: number;
  countListed: number;
  countRejected: number;
  countSold: number;
  countTotal: number;
  countUnsold: number;
  currency: CurrencyCodes;
  details: boolean;
  ebayItemId: string;
  ebayPaymentReferenceId: string;
  ebayTransactionId: string;
  giftAidNumber: string;
  giftAidValue: number;
  inventoryItemCount: number;
  leafCategory: string;
  listingAvgDuration: number;
  listingCount: number;
  name: string;
  numberOfItems: number;
  numberOfListings: number;
  numberOfSoldItems: number;
  paymentDate: string;
  paymentStatus: OrderPaymentStatus;
  quantity: number;
  realUserName: string;
  refundedAmount: number;
  rejectedItems: number;
  rootCategory: string;
  scheduledItems: number;
  scheduledOnMarketplaceItems: number;
  secondarySku: string;
  selected: boolean;
  shopLocationId: string;
  shopLocationName: string;
  sku: string;
  soldItems: number;
  soldValuePerListingTime: number;
  status: number;
  submissionRejectReason: string;
  submissionRejectType: ProductRejectReason;
  suspendedForEditingItems: number;
  teamId: string;
  teamName: string;
  timeSpentListingInSeconds: number;
  timeSpentListingPerListingInSeconds: number;
  title: string;
  totalItems: number;
  totalPrice: number;
  unitPrice: number;
  unsoldItems: number;
  unsuccessfulListings: number;
  uploadInProgressItems: number;
  userId: string;
  userName: string;
  valueOfSoldItems: number;
  waitingForApprovalItems: number;
}

export interface ReportDetailDTO {
  currency: CurrencyCodes;
  id: string;
  price: number;
  quantity: number;
  refundedAmount: number;
  title: string;
  total: number;
}

export interface OfferDTO {
  discountPercent: number;
  discountPrice: number;
  offerReachedUserCount: number;
  offerSentOn: Date;
}

export interface SendOfferDTO {
  discountPercentage: number;
  discountPrice: number;
  listingId: string;
  message: string;
  quantity: number;
}

export interface SendOfferRequestDTO {
  ebaySendOfferDTO: SendOfferDTO;
  masterListingId: string;
}

export interface SendOfferDialogDTO {
  currency: CurrencyCodes;
  imageThumbnailUrl: string;
  listingId: string;
  masterListingId: string;
  price: number;
  sku: string;
  title: string;
}

export interface SetVariantImageDialogDTO {
  listingImages: ImageDTO[];
  selectedImages: ImageDTO[];
}

export interface RateEstimateRequestDTO {
  addressResidentialIndicator: AddressResidentialIndicator;
  carrierIds: string[];
  confirmation: ShipmentConfirmation;
  depth: number;
  dimensionUnit: ShipmentDimensionUnit;
  height: number;
  recipientCity: string;
  recipientCountry: string;
  recipientPostalCode: string;
  recipientState: string;
  senderCity: string;
  senderCountry: string;
  senderPostalCode: string;
  senderState: string;
  shipDate: Date;
  weight: number;
  weightUnit: ShipmentWeightUnit;
  width: number;
}

export interface ReportTargetDTO {
  companyId: string;
  email: string;
  id: string;
}

export class ScheduledReportDTO {

  id: string;
  mailSubject: string;
  mailTemplate: string;
  manuallySent: boolean;
  name: string;
  nextRuntime: string;
  sendInterval: number;
  sendIntervalTimeUnit: ScheduledReportInterval;
  recurringReportQueryId: string;
  startTime: string;
  targets: ReportTargetDTO[];

}

export interface ScheduledReportTestDTO {
  reportDef: ScheduledReportDTO;
  testOverrideTarget: ReportTargetDTO;
}

export interface ScheduledReportListDTO extends ScheduledReportDTO {
  selected: boolean;
}

export interface ScheduledReportEventDTO {
  createdOn: string;
  email: string;
  id: string;
  name: string;
  nextRuntime: string;
  selected: boolean;
}

export interface TransferBoxCreateDTO {
  endSequenceNumber: number;
  prefix: string;
  startSequenceNumber: number;
}

export interface TransferBoxListDTO {
  companyId: string;
  createdBy: string;
  createdById: string;
  createdOn: string;
  id: string;
  selected: boolean;
  status: TransferBoxStatus;
  transferBoxId: string;
}

export interface LocationProcessConfirm {
  locationId: string;
  type: 'Delete' | 'Disable';
}

export interface LocationBoundDataDTO {
  dataId: string;
  dataType: LocationBoundDataType;
  locationId: string;
  readableValue: string;
}

export type LocationBoundDataByType = Record<string, GridResponse<LocationBoundDataDTO>>;

export type BooleanKeyDTO = Record<string, boolean>;

export type GridResponseSummaryDTO = Record<string, any>;

export interface ListingFeeResponseDTO {
  currency: CurrencyCodes;
  feeDetails: Record<string, number>;
  sumFee: number;
}

export interface ListingFeeDTO {
  title: string;
  value: string;
}

export interface RestockDataDTO {
  inventoryVariantDataId: string;
  inventoryVariantDataItemIds: string[];
  targetToteBox: string;
}

export interface RestockItemDTO {
  relocationData: RestockDataDTO[];
}

export interface ListingCancelDTO {
  endReason: ListingDeleteReason;
  id: string;
  listingThumbnailUrl: string;
  marketplace: MarketPlaces;
  status: ListingStatus;
  title: string;
}

export interface MenuOverlayGroupData<T> {
  name: string;
  url: T;
  visibleForRoles: Roles[];
}

export interface MenuOverlayData<T> {
  hidden?: boolean;
  icon: HeroIconName;
  isActive: boolean;
  link?: string;
  name: string;
  subOptions?: MenuOverlaySubOptions<T>[];
  toggleMenu?: boolean;
  url?: T;
}

export interface MenuOverlayOptions<T> {
  hidden?: boolean;
  name: string;
  url: T;
}

export interface MenuOverlaySubOptions<T> {
  options: MenuOverlayOptions<T>[];
  title?: string;
}

export interface BreadcrumbDTO<T> {
  name: string;
  url?: T;
}

export interface HeaderMenuDTO {
  name: string;
  type: HeaderMenuType;
}

export interface HeaderDropdownDTO {
  menuOptions: HeaderMenuDTO[];
  userData: AccountDataDTO;
}

export type HeaderMenuType = 'logout' | 'profileSettings';

export interface GridOptionListDTO {
  event: GridOptions;
  groupOptions?: PossibleValuesDTO<any>[];
  highlighted?: boolean;
  iconClass: HeroIconName;
  isDownloadOption?: boolean;
  isSolid?: boolean;
  optionSettings?: OptionSettingsTypes[];
  type?: 'green' | 'red';
  viewName: string;
}

export interface GridOptionSettingsDTO {
  option: GridOptionListDTO;
}

export interface GridOptionDataListDTO {
  exportOption: GridOptionListDTO;
  greenOptionList: GridOptionListDTO[];
  highlightedOption: GridOptionListDTO;
  optionList: GridOptionListDTO[];
  redOptionList: GridOptionListDTO[];
}

export interface GridSettingsDTO<T, R = T> {
  customDefaultPageSize?: number;
  customPageSize?: number[];
  defaultFields: FieldNamesArrayDTO<T, R>[];
  defaultOrder?: GridSortDTO<T, R>[];
  fieldNames: FieldNamesArrayDTO<T, R>[];
  mapping?: GridVariableMapDTO<any>;
  selectedPageSize: number;
  sorts: GridSortDTO<T, R>[];
}

export interface FilterDates {
  intervalEnd: Date;
  intervalStart: Date;
}

export interface FilterValueDTO {
  isSystemMade?: boolean;
  operation: Correlations;
  relativeValue?: RelativeValues;
  value: any;
  viewValue: string;
}

export interface FilterGroupDTO<T, R = T, E extends string = null> {
  arrayValue?: boolean;
  filterProp?: GridProps<T, R> | CamelToSnakeCase<GridProps<T, R>> | E;
  filters: FilterValueDTO[];
  hidden?: boolean;
  imageColumn?: boolean;
  isDefault?: boolean;
  isSingle?: boolean;
  prop: GridProps<T, R> | E;
  separator?: FilterSeparators;
  skuColumn?: boolean;
  type: FieldTypes;
  viewName: string;
}

export type FilterSeparators = ',' | ';';

export interface FilterEditDTO {
  filter: FilterGroupDTO<any>;
  possibleValues: PossibleValuesDTO<any>[];
}

export interface FilterEditorData {
  booleanValue: boolean;
  dateFrom: Date;
  dateTo: Date;
  hour: number;
  isNotGiven: boolean;
  listCorrelation: Correlations;
  minute: number;
  numberFilters: FinalFormGroup<NumberFormArray>[];
  numberFrom: number;
  numberTo: number;
  relativeDate: PossibleValuesDTO<any>;
  second: number;
  selectedOptions: PossibleValuesDTO<any>[];
  textFilters: FinalFormGroup<TextFormArray>[];
}

export interface ReportGridDTO<T, E extends GridProps<T> = null> {
  detailEnabled?: boolean;
  exportCallName: string;
  exportName: string;
  fields: FieldNamesArrayDTO<T>[];
  gridCallName: string;
  gridName: string;
  gridVersionNumber: number;
  gridViewValue: string;
  summaryFields?: FieldNamesArrayDTO<T>[];
  variables: GridVariableMapDTO<T, T, E>;
}

export interface ReportChartDTO {
  callName: string;
  config: ChartOptions<any>;
  type: ChartType;
}

export interface ReportConfig<T, R, E extends GridProps<T> = null> {
  chart?: ReportChartDTO;
  defaultFilters: FilterGroupDTO<T, T, 'reportDate' | 'shopLevelFilter'>[];
  grids: ReportGridDTO<T, E>[];
  isSalesReport?: boolean;
  name: string;
  receiverReport?: boolean;
  reportId: R;
  summaryChart?: boolean;
}

export interface ReportDialogDTO<T, R> {
  companyId: string;
  companyName: string;
  reportFilters: FilterGroupDTO<T>[];
  reportId: R;
}

export interface QuillMentionItemDTO {
  id: string;
  value: string;
}

export type MentionChars = '@' | '#';

export interface QuillDTO extends QuillModules {
  mention: {
    allowedChars: string | RegExp;
    blotName: 'mention';
    dataAttributes: string[];
    defaultMenuOrientation: 'bottom' | 'top';
    fixMentionsToQuill: boolean;
    isolateCharacter: boolean;
    linkTarget: string;
    listItemClass: string;
    maxChars: number;
    mentionContainerClass: string;
    mentionDenotationChars: MentionChars[];
    mentionListClass: string;
    minChars: number;
    offsetLeft: number;
    offsetTop: number;
    onBeforeClose(): void;
    onClose(): void;
    onOpen(): void;
    onSelect(item: QuillMentionItemDTO, insertItem: (item: QuillMentionItemDTO) => void): void;
    positioningStrategy: 'normal' | 'fixed' | 'absolute';
    renderItem(item: QuillMentionItemDTO[], searchTerm: string): void;
    renderLoading(): void;
    selectKeys: number[];
    showDenotationChar: boolean;
    source(searchTerm: string, renderList: (
      matches: QuillMentionItemDTO[],
      searchTerm: string,
    ) => void, mentionChar: MentionChars): void;
    spaceAfterInsert: boolean;
  };
}

export type SelectedModules = {
  [key in Modules]?: boolean;
};

export type Modules = 'ecommerce' | 'tradeIn' | 'backoffice' | 'api' | 'collection' | 'settings';

export interface ModuleSelectorDropdown {
  currentModule: Modules;
  isSettings: boolean;
  modules: ModulesDTO[];
  settingsOptions: MenuOverlaySubOptions<any>[];
}

export interface ModulesDTO {
  module: Modules;
  url: string;
  viewValue: string;
  width: number;
}

export interface TabDTO<T> {
  closable?: boolean;
  id?: string;
  isDefault?: boolean;
  type: T;
  viewValue: string;
}

export interface TabDropdownAction {
  action: 'delete' | 'view';
  tab: TabDTO<MenuPages>;
}

export interface CompanyModuleDTO {
  collectionAccess: ModuleAccessTypes;
  ecommerceAccess: ModuleAccessTypes;
  isLocationModules?: boolean;
  relatedEntity: string;
  requestSettings?: boolean;
  tradeInAccess: ModuleAccessTypes;
  vendorNumber?: string;
}

export interface ListingCancelDialogRequestDTO {
  ebayListings: ListingCancelDTO[];
  listingsForDelete: ListingCancelDTO[];
}

export interface ListingCancelRequestDTO {
  listingsForDelete: ListingCancelDTO[];
}

export interface ListingFillDTO {
  id: FormFill;
  value: boolean;
  viewValue: string;
}

export interface ListingFormGroupDTO<T> {
  groupName: keyof T;
  hasToConfirmDefaultValue?: boolean;
  name: string;
  valueSet?: boolean;
}

export interface CommonStorageData {
  defaultCamera: DefaultCameraListDTO[];
}

export interface StorageData extends CommonStorageData {
  accountSettingsTab: AccountSettingsTabs;
  activeDashboardTab: DashboardTabs;
  activePaymentTab: number;
  activeUsersTab: number;
  bookInPrint: PrintType;
  closeReleaseMessages: string;
  commonListingCloneSettings: CloneSettingsDTO<CloneOptions>;
  defaultCamera: DefaultCameraListDTO[];
  ebayListingCloneSettings: CloneSettingsDTO<CloneOptions>;
  etsyListingCloneSettings: CloneSettingsDTO<CloneOptions>;
  inventoryListTab: InventoryTabs;
  labelPrintSettings: CloneSettingsDTO<LabelPrintTypes>;
  lastEbayAccount: AccountSelectDTO;
  lastEtsyAccount: AccountSelectDTO;
  lastPrinter: string;
  lastReleaseMessage: string;
  lastShippingService: string;
  lastShopifyAccount: AccountSelectDTO;
  lastUnsuccessfulListing: Date;
  listingListTab: ListingTabs;
  marketplaceAccountsTab: number;
  marketplaceSearchHistory: string[];
  orderListTab: OrderTabs;
  packingSlipSettings: CloneSettingsDTO<PdfSortTypes>;
  printRunSettings: CloneSettingsDTO<PdfSortTypes>;
  productCloneSettings: CloneSettingsDTO<CloneOptions>;
  productDefaultDateFormat: string;
  searchFilters: MarketplaceSearchSettings;
  selectedLayout: LayoutTypes;
  selectedMessages: SelectedMessageDTO[];
  shippingMethodTab: string;
  shopiagoSearchFilters: ShopiagoSearchSettings;
  shopiagoSearchHistory: string[];
  shopifyListingCloneSettings: CloneSettingsDTO<CloneOptions>;
}

export interface GroupByResponseDTO<T> {
  key: string;
  value: T[];
}

export interface AffectedUsersComponentDTO {
  excludedIds?: string[];
  gridRequest?: GridRequest;
  locationIds?: string[];
}

export interface LocationAffectedUsers {
  attachedUsers: ActiveUserDTO[];
  companyId: string;
  companyName: string;
  itemLocationId: string;
  itemLocationName: string;
}

export type ListingTemplateTagName = 'name' | 'description' | 'image' | 'gallery' | 'price';

export type LocationCSVHeaders = 'Account Name' | 'Location Name' | 'Country' | 'County' | 'Postal Code' | 'Town' | 'Email Address' |
'Primary Address' | 'Secondary Address' | 'Vendor Number' | 'Manager Name' | 'Phone Number' | 'Location Type' | 'Area Code';

export type ExtractKey<T = any> = Extract<keyof T, string>;

export type ListingErrorTypes = addPrefix<keyof MasterListingDTO, 'masterListingDTO.'> |
addPrefix<keyof EbayListingDTO, 'ebayListingDTO.'> | addPrefix<keyof EtsyListingDTO, 'etsyListingDTO.'> |
addPrefix<keyof ShopifyListingDTO, 'shopifyListingDTO.'> | 'ebayListingDTO.shippingMethods.price';

export type ListingDTOTypes = 'ebayListingDTO' | 'etsyListingDTO' | 'shopifyListingDTO';

export type AccountSettingsTabs = 'company' | 'ebay' | 'system' | 'shipment' | 'giftAid';

export type addPrefix<TKey, TPrefix extends string> = TKey extends string
  ? `${TPrefix}${TKey}`
  : never;

export type KebabToCamel<T extends string> =
  T extends `${infer First}-${infer Second}`
    ? `${First}${Capitalize<KebabToCamel<Second>>}`
    : T;

export type NonCamelSeparators = ' ' | '-' | '_';

export type CamelCase<T extends string> =
  T extends `${NonCamelSeparators}${infer Second}`
    ? CamelCase<Second>
    : T extends `${infer First}${NonCamelSeparators}`
      ? CamelCase<First>
      : T extends `${infer First}${NonCamelSeparators}${infer Second}`
        ? CamelCase<`${First}${Capitalize<Second>}`>
        : T;

export type Features = CamelCase<'enableOnlyShowSearchSettings' | 'enableSearchCardView' | 'etsyEnabled' | 'inventoryImportEnabled' |
'collectionEnabled' | 'collectionCreateEnabled' | 'printerServiceBetaEnabled' | 'enableBasketExport' | 'enableOperatorArranged'>;

export type cdkXPositions = 'start' | 'center' | 'end';

export type cdkYPositions = 'bottom' | 'center' | 'top';

export type GridOptions = 'AddTag' | 'Accept' | 'AdvanceView' | 'Approval' | 'Approve' | 'Archive' | 'Assign' |
'Back' | 'BasicView' | 'BankAccount' | 'BookIn' | 'BulkEdit' | 'BulkFulFill' | 'BulkRelist' | 'Cancel' | 'Close' |
'CancelCulling' | 'CapturePayment' | 'Create' | 'CloneAsNew' | 'ColumnSettings' | 'CommissionRate' | 'Refuse' |
'ContinuousSync' | 'Culling' | 'Delete' | 'Disable' | 'Download' | 'DownloadCustoms' | 'DownloadLabel' | 'SetPickingStatus' |
'Duplicate' | 'Enable' | 'ExportAll' | 'ExportResults' | 'ExportSelected' | 'FulFill' | 'GetToken' | 'AssignToPicker' |
'Highlight' | 'HighlightRemove' | 'Impersonate' | 'Invite' | 'ReInvite' | 'KeepForever' | 'KeepForeverRemove' | 'DeselectAll' |
'ListItem' | 'MergeItems' | 'Open' | 'Link' | 'ManageRoles' | 'MessageSync' | 'Modify' | 'Move' | 'ParcelCreate' |
'PackingSlip' | 'PackingSlipFromPickList' | 'Picked' | 'PrintCustomSku' | 'PrintLabel' | 'PrintRun' | 'PrintSku' |
'Reattach' | 'Reject' | 'Relist' | 'Reply' | 'RequestCISListing' | 'RemoveFromCis' | 'RemoveFromTest' | 'RemoveFromDefault' |
'RemoveFromInternationalDefault' | 'Revise' | 'Run' | 'Search' | 'SelectAll' | 'SelectPage' | 'SendOffer' | 'SetBusinessLead' |
'SendToWarehouse' | 'SetAsCis' | 'SetAsTest' | 'SetRule' | 'SetToDefault' | 'SetToInternationalDefault' | 'MarkAsFailed' |
'SetStatus' | 'SkuSequence' | 'Sync' | 'TakeImage' | 'Tracking' | 'TradeSize' | 'ViewCustomer' | 'BulkImageCapture' |
'ViewListing' | 'ViewOrder' | 'ViewProduct' | 'Void' | 'Unarchive' | 'UnSelectPage' | 'UnSelectAll' | 'RemoveAssignment' |
'UploadImage' | 'WarehouseLocationChange' | 'ProductSettings' | 'CompanySettings' | 'BulkImport' | 'ManageLocation' |
'PaymentSettings' | 'TradeInPaymentSettings' | 'EmailNotificationSettings' | 'CollectionSettings' | 'PackageTypeSettings' |
'SetHubspotId' | 'ImageDelete';

export type TimelineTypes = 'listing' | 'order' | 'inventory';

export type IframeTypes = 'wysiwgEditor' | 'templateViewer';

export type SnackbarType = 'Error' | 'Success';

export type InventoryTabs = 'products' | 'shopProducts' | 'cisShopProducts' | 'submittedItems';

export type OrderTabs = 'ordersAdvanced' | 'orders' | 'ordersPicklist' | 'cisOrders' | 'cisPicklistOrders';

export type ListingTabs = 'listings' | 'savedListings' | 'revises';

export type MenuPages = 'dashboard' | 'accounts' | 'invoices' | 'scheduler' | 'blockedKeywords' | 'customers' |
'report' | 'ebaymessages' | 'ebaypolicies' | 'allowedShippingMethods' | 'etsyShippingMethods' | 'flatrates' |
'imagegallery' | 'productImportHistory' | InventoryTabs | ListingTabs | OrderTabs | 'htmltemplates' | 'locations' |
'messageAssignedToMe' | 'messagetemplates' | 'notification' | 'pendingUsers' | 'productSettings' | 'repriceRules' |
'settings' | 'shipments' | 'teams' | 'transferBoxes' | 'tokenRequests' | 'defaultvalues' | 'users' | 'productChanges' |
'listingChanges' | 'paymentMethods' | 'reportDetails' | 'giftAidRules' | 'locationImportList';

export type OptionSettingsTypes = 'commonListingCloneSettings' | 'ebayListingCloneSettings' | 'etsyListingCloneSettings' |
'shopifyListingCloneSettings' | 'productCloneSettings' | 'packingSlipSettings' | 'printRunSettings' |
'labelPrintSettings';

export type UploadTypes = 'allAddedToQueue' | 'addedToQueue' | 'uploadFile' | 'start' | 'removed' | 'remove' | 'removeAll'
| 'removedAll' | 'rejected' | 'dragEnter' | 'dragOver' | 'dragOut' | 'drop';

export type ReplyTypes = 'asq' | 'ctp';

export type LoadFrom = 'ebay' | 'etsy' | 'amazon' | 'shopify' | 'search' | 'template' | 'draft' |
'listing' | 'revise' | 'shopiago' | 'item';

export type UserEvent = 'create' | 'createandnew' | 'from' | 'newfrom' | 'modify' | 'merge' | 'details' | 'revise' |
'relist' | 'list' | 'search' | 'save' | 'save/template' | 'save/draft' | 'add' | 'remove' | 'edit' |
'delete' | 'close' | 'approve' | 'reject' | 'takeimage';

export type Correlations = 'startswith' | 'equal' | 'equal_match_case' | 'contains' | 'endswith' | 'in' | 'less' |
'not_in' | 'greater' | 'not_equal' | 'not_equal_match_case' | 'not_in_match_case' | 'value_exists' |
'value_not_exists' | 'less_or_equal' | 'greater_or_equal' | 'contains_similar';

export type FormFill = 'name' | 'category' | 'description' | 'price' | 'image';

export type Urls = 'dashboard' | 'login' | 'listings' | 'listing' | 'inventory' | 'inventory/item' | 'inventory/item/create' |
'listings/savedlistings' | 'settings/myaccount' | 'reports' | 'settings/myprofile' | 'settings/myprofile/detail' |
'ebaymessages' | 'customers/details' | 'customers' | 'settings/manageaccounts' | 'settings/inventoryattributes' |
'settings/inventoryattributes/create' | 'settings/inventoryattributes/modify' | 'images' | 'orders/details' | 'revise' |
'orders' | 'settings/users' | 'shipment/details' | 'shipments' | 'notifications' | 'settings/locations/create' |
'settings/locations' | 'settings/locations/modify' | 'settings/shippingmethods/ebay/create' | 'listings/revises' |
'settings/shippingmethods' | 'settings/teams/create' | 'settings/teams/details' | 'listing/create' | 'settings/users/detail' |
'settings/teams' | 'settings/teams/modify' | 'settings/repricerule/create' | 'settings/repricerules' | 'settings/inventoryimport' |
'settings/repricerule/modify' | 'settings/messagetemplates' | 'settings/messagetemplates/create' |
'settings/messagetemplates/modify' | 'settings/htmltemplates/create' | 'settings/htmltemplates' | 'settings/transfertotes' |
'settings/htmltemplates/modify' | 'settings/htmltemplates/details' | 'logout' | 'settings/defaultvalues/create' |
'settings/defaultvalues' | 'settings/defaultvalues/modify' | 'settings/blockedkeywords' | 'settings/flatrates' |
'settings/automatedjobs' | 'settings/tokenrequests' | 'settings/ebaypolicies' |
'settings/bankaccount' | 'settings/emailpreferences' | 'registrationsuccess' | 'reports/custom' | 'reports/userperformance'
| 'reports/giftaid' | 'reports/areasales' | 'reports/shopsales' | 'reports/usersales' | 'reports/categorysales' |
'reports/teamperformance' | 'reports/teaminventory' | 'reports/teamlisting' | 'reports/teamsales' | 'reports/shopsubmissionstatus' |
'reports/rejectreason' | 'settings/users/modify' | 'settings/giftaidrule/create' | 'settings/giftaidrule/modify' |
'inventory/item/modify';

export type Reports = 'areasales' | 'categorysales' | 'report' | 'giftaid' | 'shopsales' | 'shopLocationless' |
'shopSummary' | 'teamlisting' | 'teaminventory' | 'teamperformance' | 'teamsales' | 'userperformance' | 'usersales' |
'shopsubmissionstatus' | 'rejectreason';

export type IdentifierType = 'EAN' | 'ISBN' | 'UPC' | 'MPN' | 'GTIN';

export type ExportTypes = 'listing/multilistings' | 'order' | 'order/item' | 'order/shop/item' | 'inventory/item' |
'account/accountData' | 'order/picking' | 'order/picking/shop' | 'inventory/location';

export type QuickLinks = 'productCreate' | 'listingCreate' | 'locationCreate' | 'shipmentCreate';

export type SearchFilters = 'keyword' | 'ean' | 'isbn' | 'condition' | 'listingType' | 'soldItemsOnly' |
'listingStatus' | 'ebaySite' | 'searchAreas' | 'completedListings';

export type ItemSpecificsTypes = 'optionalSpecifics' | 'recommendedSpecifics' | 'requiredSpecifics';

export type Sorting = 'asc' | 'desc';

export type ItemSpecificType = 'FreeText' | 'SelectionOnly';

export type SearchTarget = 'shopiago' | 'ebay';

export type ValidationErrorType = 'equal' | 'required' | 'bigger' | 'invalid' | 'max' | 'min' | 'matDatepickerFilter' | 'duplicate' |
'matDatepickerMin' | 'matDatepickerParse' | 'pattern' | 'passwordMismatch' | 'minlength' | 'maxlength' |
'notSelected' | 'globalEqual' | 'maxDifference' | 'invalidCode39' | 'notFound' | 'lower' | 'server';

export type SearchGroups = 'ebaySearchFilterGroup' | 'shopiagoSearchFilterGroup';

export type ReceiverReportDateType = 'DAY' | 'WEEK' | 'MONTH';

export type CurrencyCodes = 'USD' | 'AUD' | 'GBP' | 'CAD' | 'DKK' | 'EUR' | 'HKD' | 'HUF' | 'IDR' | 'ILS' | 'MYR' |
'MXN' | 'MAD' | 'NZD' | 'NOK' | 'PHP' | 'SGD' | 'ZAR' | 'SEK' | 'CHF' | 'TRY' | 'VND';

export type EbaySites = 'EBAY-AU' | 'EBAY-DE' | 'EBAY_ES' | 'EBAY-FR' | 'EBAY_IT' | 'EBAY-GB' | 'EBAY-IE' |
'EBAY-US';

export type EbayDomainNames = 'com.au' | 'de' | 'fr' | 'co.uk' | 'ie' | 'com';

export type ListingLocations = 'Australia' | 'Germany' | 'France' | 'United Kingdom' | 'Ireland' | 'USA';

export type ListingSites = 'AUSTRALIA' | 'GERMANY' | 'FRANCE' | 'UK' | 'IRELAND' | 'US' | 'ETSY' | 'SHOPIFY';

export type OrderPrintType = 'PackingSlip' | 'PrintRun';

export type PrintType = 'PrintSku' | 'PrintCustomSku' | 'PrintLabel';

export type CookieName = 'shopiagoCookieAccept' | 'shopiagoApiCookieAccept' | 'ziffitProCookieAccept' | 'collectionCookieAccept';

export type ApiTypes = 'cybertill' | 'shipEngine';

export type DashboardTabs = 'statistics' | 'communicationBoard' | 'releaseMessages' | 'rejectedListings';

export type Operations = 'min' | 'max' | 'sum' | 'count' | 'avg' | 'median' | 'groupby';

export interface Device {
  connection: string;
  deviceType: string;
  manufacturer: string;
  name: string;
  provider: string;
  uid: string;
  version: number;
}

export type CookieNames = 'loginToken' | 'refreshToken' | 'loginTokenLastChecked' | 'hubspotToken';

export type MarketplaceTypes = 'eBay' | 'Etsy' | 'Shopify';

export type VariantDataTypes = 'variantProperties' | 'sku' | 'price' | 'quantity';

export type HeroIconNameList = 'adjustments' | 'arrow-circle-right' | 'arrow-left' | 'arrow-right' |
'arrow-up' | 'arrows-expand' | 'bell' | 'calendar' | 'camera' | 'chart-bar' | 'chart-pie' | 'chat-alt' |
'check-circle' | 'chevron-down' | 'check' | 'chevron-left' | 'chevron-right' | 'chevron-up' | 'dots-vertical' |
'clipboard-list' | 'code' | 'clock' | 'cog' | 'collection' | 'credit-card' | 'cube' | 'currency-pound' |
'database' | 'document-add' | 'document-text' | 'dots-horizontal' | 'duplicate' | 'exclamation' | 'external-link' | 'eye' |
'filter' | 'folder-add' | 'hashtag' | 'inbox' | 'folder' | 'information-circle' | 'link' | 'location-marker' | 'eye-slash' |
'lock-closed' | 'lock-open' | 'mail' | 'menu' | 'minus-circle' | 'minus' | 'paper-airplane' | 'paper-clip' | 'pencil-alt' |
'pencil' | 'photograph' | 'play' | 'plus-circle' | 'plus' | 'presentation-chart-line' | 'printer' | 'qrcode' |
'question-mark-circle' | 'receipt-tax' | 'refresh' | 'reply' | 'save' | 'search' | 'shopping-cart' | 'star' | 'sun' |
'switch-vertical' | 'tag' | 'stop' | 'trash' | 'trending-up' | 'truck' | 'crop' | 'keyframes' | 'user-add' | 'user' |
'users' | 'view-boards' | 'view-grid' | 'x-circle' | 'x' | 'restart' | 'graph-down' | 'rotate-camera-left' | 'table2columns' |
'rotate-camera-right' | 'flip' | 'thumb-up' | 'hand' | 'map' | 'building-storefront' | 'archive-box-arrow-down';

export type HeroIconName = HeroIconNameList | `${HeroIconNameList}-solid`;

export type HeroIconsList = Partial<Record<KebabToCamel<HeroIconName>, string>>;

export type Environments = 'dev' | 'staging' | 'rc' | '';

export type LocalPorts = '4200' | '4500' | '4600' | '4400' | '4300';

export type DisableFunctions = 'disableAutoCancel' | 'disableAutoRenewal';

export type ModuleType = 'ECOMMERCE' | 'TRADE_IN' | 'COLLECTION';

export type CountryCodes = 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' |
'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' |
'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' |
'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' |
'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' |
'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' |
'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' |
'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' |
'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' |
'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' |
'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' |
'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' |
'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' |
'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW' |
'AN' | 'XK';

export type DiffValue = string | number | boolean;

export type TimeType = 'HH' | 'mm' | 'ss';

export interface ItemLocationOfVendorNumber {
  address: string;
  companyId: string;
  companyName: string;
  itemLocationId: string;
  itemLocationName: string;
  vendorNumber: string;
}

export interface ItemLocationOfCompany extends ItemLocation, ItemLocationOfVendorNumber {
}

export interface ItemLocation {
  address: string;
  value: string;
  vendorNumber: string;
  viewValue: string;
}

export interface CollectionsBackofficeRequest extends Interval {
  request: GridRequest;
}

export interface CategoryQuickSearchData {
  control: AbstractControl<string>;
  ebaySiteValue: ListingSites;
  marketplace: MarketplaceTypes;
}

export interface PopoverData {
  content: string;
  width: string;
}

export type BulkScanStatus = 'CREATING' | 'FAILED' | 'PROCESSED' | 'PROCESSING' | 'PROGRESSED' | 'REFUSED';

export type CollectionShippingProvider = 'MAX_OPTRA' | 'MENZIES' | 'SEDAT';

export type PackageTypes = 'BLACK_TOTE' | 'CARDBOARD_BOX' | 'MAGNUM' | 'RED_TOTE' | 'SACK' | 'UNKNOWN' | 'YELLOW_TOTE';

export type ProcessingMethod = 'CARTON' | 'SINGLE';

export type ScheduledCallResultType = 'BOOKING_MADE' | 'COLLECTION_SKIPPED' | 'DIDNT_ANSWER';

export type ScheduledCallType = 'FAILED' | 'PLANNED';

export type UserGroupNames = 'account_owner' | 'backoffice_manager' | 'developer' | 'disabled' | 'user';
